import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export function useGetFormBuilder(id) {
  const api = useQuery({
    queryKey: ["form", id],
    queryFn: async () => {
      const res = await axios.get(`${process.env.REACT_APP_SERVER2}/api/form-builder/${id}`)
      return JSON.parse(res?.data?.data)
    },
    enabled: !!id
  })
  return api
}

export async function ocr(codegambarbase64) {
  let hasil = "";
  // modalloading()
  await axios
    .post(`${process.env.REACT_APP_SERVER2}/api/digitech/ocr-ktp`, {
      image: codegambarbase64,
    })
    .then((res) => {
      const hasilaxios = res.data.data;
      console.log(hasilaxios);
      const ocrktp = {
        noidentitas: hasilaxios.nik,
        namasesuaiidentitas: hasilaxios.nama,
        tempatlahir: hasilaxios.ttl ? hasilaxios.ttl.split(",")[0] : null,
        tanggallahir: hasilaxios.ttl ? hasilaxios.ttl.split(",")[1] : null,
        jeniskelamin:
          hasilaxios.jeniskelamin === "LAKI-LAKI"
            ? "Laki - Laki"
            : hasilaxios.jeniskelamin,
        golongan_darah: hasilaxios.golongan_darah,
        alamatsesuaiidentitas: hasilaxios.alamat,
        rt: hasilaxios.rtrw ? hasilaxios.rtrw.split("/")[0] : null,
        rw: hasilaxios.rtrw ? hasilaxios.rtrw.split("/")[1] : null,
        kelurahandesa: hasilaxios.desa_kelurahan,
        kabupatenkota: hasilaxios.kota_kabupaten,
        kecamatan: hasilaxios.kecamatan,
        provinsi: hasilaxios.provinsi,
        agama: hasilaxios.agama
          ? `${hasilaxios.agama.valueOf().substring(0, 1)}${hasilaxios.agama
              .valueOf()
              .substring(1, hasilaxios.agama.valueOf().length)
              .toLowerCase()}`
          : null,
        statusmenikah:
          hasilaxios.status_perkawinan === "KAWIN"
            ? "Menikah"
            : "Belum Menikah",
        pekerjaan: hasilaxios.pekerjaan,
        kewarganegaraan: hasilaxios.kewarganegaraan,
        namaibukandung: "",
        // berlakuhingga: "SEUMUR HIDUP",
      };

      hasil = ocrktp;
    })
    .catch((error) => {
      hasil = error.response.status;
      console.log(error);
      // console.log(error.response);
      // console.log(error.response.data);
      // throw error;
      //console.log(error.config);
    });
  return hasil;
}

export async function ocrnpwp(codegambarbase64) {
  let hasil = "";
  // modalloading()
  await axios
    .post(`${process.env.REACT_APP_SERVER2}/api/digitech/ocr-npwp`, {
      image: codegambarbase64,
    })
    .then((res) => {
      hasil = res.data.data.npwpId; //string
    })
    .catch(function (error) {
      hasil = { error: error.response.status }; //code 200 => number
      // hasil = error.response //code 200 => number
      // alert(error.response.data.data[0].message);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status); //code 200 (number)
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      //console.log(error.config);
    });
  return hasil;
}

export async function validasiDukcapil(payload) {
  return await axios
    .post(
      `${process.env.REACT_APP_SERVER2}/api/customer-portfolio/validasi/dukcapil-v2`,
      payload
    )
    .then((res) => {
      return { status: true, data: res.data };
    })
    .catch((err) => {
      return { status: false, data: err };
    });
}

export async function validasiDTTOT(payload) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER2}/api/customer-portfolio/validasi/dttot-v2`,
      payload
    );
    return { status: true, data: response.data };
  } catch (err) {
    return { status: false, data: err };
  }
}

export function cropImage(picture) {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = picture;
    console.log(image.width);
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = 350;
      canvas.height = 350;
      const ctx = canvas.getContext("2d");
      ctx.translate(canvas.width, 0);
      ctx.scale(-1, 1);
      ctx.drawImage(
        image,
        image.width / 2 - (image.width * 5) / 100,
        image.height / 2 - (image.height * 40) / 100,
        image.width,
        image.height,
        0,
        0,
        image.width,
        image.height
      );
      const dataURL = canvas.toDataURL();
      console.log(dataURL);
      resolve(dataURL);
    };
  });
}

const resizeImage = (file) => {
  let resizedFile = null;
  const targetCanvas = document.createElement("canvas");
  const ctx = targetCanvas.getContext("2d");
  const img = new Image();
  img.src = URL.createObjectURL(file);
  img.onload = () => {
    targetCanvas.width = 500;
    targetCanvas.height = 500 * (img.height / img.width);
    ctx.drawImage(img, 0, 0, targetCanvas.width, targetCanvas.height);
    targetCanvas.toBlob(function (resizedBlob) {
      resizedFile = new File([resizedBlob], "resizedImage", {
        lastModified: new Date().getTime(),
        type: "image/jpeg",
      });
    });
    // console.log(targetCanvas.toDataURL())
  };
  return resizedFile;
};

const resizeBase64Image = (base64, width, height) => {
  // Create a canvas element

  const canvas = document.createElement("canvas");

  // Create an image element from the base64 string
  const image = new Image();
  image.src = base64;

  // Return a Promise that resolves when the image has loaded
  return new Promise((resolve, reject) => {
    image.onload = () => {
      // Calculate the aspect ratio of the image
      const aspectRatio = image.width / image.height;

      // Calculate the best fit dimensions for the canvas
      if (width / height > aspectRatio) {
        canvas.width = height * aspectRatio;
        canvas.height = height;
      } else {
        canvas.width = width;
        canvas.height = width / aspectRatio;
      }

      // Draw the image to the canvas
      canvas
        .getContext("2d")
        .drawImage(image, 0, 0, canvas.width, canvas.height);

      // Resolve the Promise with the resized image as a base64 string
      resolve(canvas.toDataURL());
    };

    image.onerror = reject;
  });
};

export async function cropCustFace(base64Face) {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = base64Face;
    console.log(image.width);
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = 350;
      canvas.height = 350;
      const ctx = canvas.getContext("2d");
      ctx.translate(canvas.width, 0);
      ctx.scale(-1, 1);
      ctx.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        0,
        0,
        image.width,
        image.height
      );
      console.log(canvas);
      const dataURL = canvas.toDataURL();
      console.log(dataURL);
      resolve(dataURL);
    };
  });
}

export async function validasiIbuKandung(payload) {
  return await axios
    .post(
      `${process.env.REACT_APP_SERVER2}/api/customer-portfolio/validasi/ibu-kandung`,
      payload
    )
    .then((res) => {
      return { status: true, data: res.data };
    })
    .catch((err) => {
      return { status: false, data: err };
    });
}

export async function convertImageToFile(image, name) {
  const decodedData = atob(
    image
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
  );
  const buffer = new ArrayBuffer(decodedData.length);
  const array = new Uint8Array(buffer);

  for (let i = 0; i < decodedData.length; i++) {
    array[i] = decodedData.charCodeAt(i);
  }
  const blob = new Blob([buffer], { type: "image/jpeg" });
  const file = new File([blob], name, {
    lastModified: new Date().getTime(),
    type: "image/jpeg",
  });
  const resize = await resizeImage(file);
  return file;
}

export async function cekswafotoktp(data) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER2}/form-data-swafoto/check`,
      data
    );
    console.log(response);
    return { status: true, data: response };
  } catch (err) {
    return { status: false, data: err };
  }
}

export async function validasiSwafoto(image) {
  try {
    console.log(image);
    const base64 = await cropImage(image);
    // const file = await convertImageToFile(base64, "crop.jpeg");
    const result = await ocr(base64.split(",")[1])
      .then((res) => {
        console.log(res);
        if (res === 400) {
          return { status: false };
        }
        return { status: true, data: res };
      })
      .catch((err) => {
        console.log(err);
        return { status: false };
      });

    return result;
  } catch {
    return {};
  }
}

export async function apiuploaddokumen(pic, jenisgambar, namaform, idDips) {
  let hasil = "";
  const formData = new FormData();
  let file = await convertImageToFile(pic, `blob${jenisgambar}.png`);
  formData.append("idDips", idDips); //ttd
  formData.append(jenisgambar, file);

  await axios
    .post(`${process.env.REACT_APP_SERVER2}/form-data/${namaform}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      hasil = res;
      console.log("res coba2 ya", res);
    })
    .catch(function (error) {
      hasil = { error: error.response.status };
      // hasil = error.response //code 200 => number
      alert(error.response.data.message);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data.message);
        console.log(error.response.data);
        console.log(error.response.status); //code 200 (number)
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      //console.log(error.config);
    });
  return hasil;
}

export async function formporto(payload) {
  let hasil = "";
  await axios
    .post(
      `${process.env.REACT_APP_SERVER2}/api/customer-portfolio/portfolio-data`,
      payload
    )
    .then((res) => {
      hasil = res;
    })
    .catch(function (error) {
      hasil = "error";
      console.log(error);
      // hasil = error.response //code 200 => number
      // alert(error.response.message);
      // alert(error.response.data[0].message.replace("datadiri.", ""));
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data.message);
        console.log(error.response.data);
        console.log(error.response.status); //code 200 (number)
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      //console.log(error.config);
    });
  return hasil;
}

export async function formsave(payload) {
  let hasil = "";
  await axios
    .post(`${process.env.REACT_APP_SERVER2}/api/form/save`, payload)
    .then((res) => {
      hasil = res.data.data.idForm;
    })
    .catch(function (error) {
      hasil = "error"; //code 200 => number
      // alert(error.response.data.data[0].message)
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data.data[0].message);
        console.log(error.response.data);
        console.log(error.response.status); //code 200 (number)
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      //console.log(error.config);
    });
  return hasil;
}

export async function apiswafotocompare(payload) {
  let hasil = "";
  await axios
    .post(
      `${process.env.REACT_APP_SERVER2}/api/digitech/swafoto-compare`,
      payload
    )
    .then((res) => {
      hasil = res;
      // pindahformupload()
    })
    .catch(function (error) {
      alert(
        `Error Komparasi Swafoto:\n${error.response.data.message}\n${error.response.data.name}\n\nCoba ulangi kembali!`
      );
      hasil = "error"; //code 200 => number
      alert(error.response.data.message);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data.message);
        console.log(error.response.data);
        console.log(error.response.status); //code 200 (number)
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      //console.log(error.config);
    });
  return hasil;
}

export async function getlistfromurl(
  url,
  namakolom,
  idprovinsi,
  idkabupaten,
  idkecamatan,
  jenispengaduan
) {
  let hasil = "";
  // let bahanreplace = ':id_' + namakolom.valueOf().toLowerCase().split("(")[0].split("/")[0].split(" ")[0];
  await axios
    .get(
      url
        .replace(":id_provinsi", idprovinsi)
        .replace(":id_kabupaten", idkabupaten)
        .replace(":id_kecamatan", idkecamatan)
        .replace(":jenisPengaduan", jenispengaduan)
    )
    .then((res) => {
      hasil = res.data.data;
    })
    .catch(function (error) {
      hasil = "error"; //code 200 => number
      if (error.response) {
        // alert(`${useTranslation('error')}:${useTranslation('tidakdapatmengambildatauntukkolom')} '${namakolom}'`)
        console.log(`${namakolom}: ${error.response.data.message}`);
        // console.log(error.response.data.message);
        console.log(error.response.data);
        console.log("error code:", error.response.status); //code 200 (number)
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      //console.log(error.config);
    });
  return hasil;
}

export async function getkodepos({ kel, kec, kab, prov }) {
  let hasil = "";
  // let bahanreplace = ':id_' + namakolom.valueOf().toLowerCase().split("(")[0].split("/")[0].split(" ")[0];
  await axios
    .post(`${process.env.REACT_APP_SERVER2}/api/kodepos/get-by-wilayah`, {
      kelurahan: kel,
      kecamatan: kec,
      kabupaten: kab,
      provinsi: prov,
    })
    .then((res) => {
      hasil = res.data.data;
    })
    .catch(function (error) {
      hasil = error.response?.data?.data; //code 200 => number
      if (error.response) {
        console.log(`${error.response.data.message}`);
        console.log(error.response.data);
        console.log("error code:", error.response.status); //code 200 (number)
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      //console.log(error.config);
    });
  return hasil;
}

export async function kirimformtransaksi(url, payload, config) {
  let hasil = "";
  await axios
    .post(`${process.env.REACT_APP_SERVER2}${url}`, payload, config)
    .then((res) => {
      hasil = res.data;
    })
    .catch(function (error) {
      hasil = error; //code 200 => number
      if (url !== "/api/rating-agent/") {
        console.log(typeof error.response.data);
        if (typeof error.response.data === "object") {
          if (url === "/api/customer-portfolio/validasi/wajah-nasabah") {
            // if (error.response.data.data.respCode === "11") {
            //   <Modalbuatan show={true} judul="Error" isi={error.response.data.message} />
            // } else {
            // }
          } else {
            // if (url !== "/api/customer-portfolio/validasi/data-nasabah")
            // alert(error.response.data.message);
          }
        }
      }

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data.message);
        console.log(error.response.data);
        console.log(error.response.status); //code 200 (number)
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      //console.log(error.config);
    });
  return hasil;
}

export async function getstatusapproval(idform) {
  let hasil = "";
  await axios
    .get(`${process.env.REACT_APP_SERVER2}/api/approval/status/${idform}`)
    .then((res) => {
      hasil = res.data; //.code
    })
    .catch(function (error) {
      // hasil = "error" //code 200 => number
      // alert(error.response.data.data[0].message)
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data.data[0].message);
        hasil = error.response.status;
        console.log(error.response.data);
        console.log(error.response.status); //code 200 (number)
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      //console.log(error.config);
    });
  return hasil;
}

export async function ambilnomorform() {
  let hasil = { sukses: null, gagal: null };
  await axios
    .get(`${process.env.REACT_APP_SERVER2}/api/transaction/generate-noForm`)
    .then((res) => {
      hasil.sukses = res.data.data.noForm;
    })
    .catch(function (error) {
      // hasil = "error" //code 200 => number
      // alert(error.response.data.data[0].message)
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data.data[0].message);
        hasil.gagal = error.response.status;
        console.log(error.response.data);
        console.log(error.response.status); //code 200 (number)
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      //console.log(error.config);
    });
  return hasil;
}

export async function GetDataKtp(authData, language) {
  let lowercasedData = {};

  await axios
    .get(
      `${process.env.REACT_APP_SERVER2}/api/ktp/get-by-idDips/${
        authData?.idDips
      }?bahasa=${language === "Idn" || language === undefined ? "id" : "en"}`
    )
    .then((res) => {
      console.log(res.data.data);
      let itemsDataKtp = res.data.data;
      for (let key in itemsDataKtp) {
        lowercasedData[key.toLowerCase()] = itemsDataKtp[key];
      }
    })
    .catch((err) => {
      lowercasedData = "error";
      console.log(err);
    });

  return lowercasedData;
}

export async function getDataCore(idDips, noCif) {
  let ktp = null;

  await axios
    .post(`${process.env.REACT_APP_SERVER2}/api/customer/get-data-core`, {
      idDips,
      noCif,
    })
    .then((res) => {
      ktp = res.data.data;
      console.log("res getdatacode", res);
    })
    .catch((err) => {
      ktp = "error";
      console.log(err);
    });

  return ktp;
}
