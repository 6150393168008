import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Figure,
  Card,
  Form,
} from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import mirroring from "../mirroring.js";
import {
  useContext,
  useLayoutEffect,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { ZoomClientContext } from "../../ZoomClientContext.js";
import Webcameasy from "webcam-easy";
import Webcam from "react-webcam";
import {
  validasiDukcapil,
  apiuploaddokumen,
  ocr,
  validasiDTTOT,
  validasiIbuKandung,
  convertImageToFile,
  cekswafotoktp,
  kirimformtransaksi,
  validasiSwafoto,
} from "../apiaxios";
import { t } from "i18next";
import UploadIcon from "../DipsGraphicMobileUI/UploadIcon";
import XImage from "../DipsGraphicMobileUI/x2.png";
import CeklistBerhasil from "../DipsGraphicMobileUI/CeklistBerhasil.png";

export function Modalbuatan(props) {
  return (
    <>
      <Modal
        centered
        show={props.show}
        onHide={props.onhide}
        backdrop="static"
        style={{ fontFamily: "Poppins" }}
        dialogClassName="border-radius-2 w400 padlef50"
        className="text-center"
      >
        <div className="align-middle" style={{ margin: "10px 0" }}>
          {/* <Col> */}
          <img
            alt=""
            aria-label=""
            className="mx-auto"
            style={{ width: "78px", height: "80px", padding: "inherit" }}
            src={props.srcicon}
            hidden={props.srcicon === "" || props.srcicon === undefined}
          ></img>
          {/* </Col> */}
        </div>
        <div
          className="lh-base"
          style={{
            // color: "#00000080",
            // fontSize: "12px",
            // position: "relative",
            // marginTop: "35px",
          }}
        >
          <div className="fw-bold h6 mb-2">{props.judul}</div>
          <div>{props.isi}</div>
        </div>
        <Modal.Footer style={{ border: "none" }}>
          <Col hidden={props.classkiri === "" || props.classkiri === undefined}>
            <button className={props.classkiri} onClick={props.fungsikiri} disabled={props.disabledKiri}>
              {props.labelkiri}
            </button>
          </Col>
          <Col>
            <button className={props.classkanan} onClick={props.fungsikanan} disabled={props.disabledKanan}>
              {props.labelkanan}
            </button>
          </Col>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export function ModalPlatform(props) {
  return (
    <>
      <Modal
        centered
        show={props.show}
        onHide={props.onhide}
        backdrop="static"
        style={{ fontFamily: "Poppins" }}
        dialogClassName="border-radius-2"
        className="text-center"
      >
        <div className="align-middle" >
          {/* <Col> */}
          <img
            alt=""
            aria-label=""
            className="mx-auto"
            style={{ width: "78px", height: "80px", padding: "inherit" }}
            src={props.srcicon}
            hidden={props.srcicon === "" || props.srcicon === undefined}
          ></img>
          {/* </Col> */}
        </div>
        <div
          className="lh-base"
        >
          <div>{props.isi}</div>
        </div>
      </Modal>
    </>
  );
}

export function Modaltnc(props) {
  const { t } = useTranslation();
  const { zoomContext } = useContext(ZoomClientContext);
  console.log("props", props);

  useEffect(() => {
    const e = {
      target: {
        checked: false
      }
    }
    mirroring(handlePayload(e), 999, zoomContext?.csId);
  }, [])
  // const [isi, setIsi] = useState(
  //   props.isi
  //     // .replace('<p><br></p>', '')
  //     .replaceAll(/<br>/g, "")
  // );
  function handlePayload(e) {
    if (props?.sendkey === "pembukaanrekening") {
      return {
        pembukaanakun: {
          tnc35: e.target.checked,
        },
      };
    }
    else if (props?.sendkey === "ibmb") {
      return {
        [`${props.sendkey}`]: {
          tnc: e.target.checked,
        },
      };
    }
    else {
      return { [`${props.sendkey}`]: e.target.checked };
    }
  }
  return (
    <>
      <Modal //pembukaan akun
        centered
        show={props.show}
        onHide={props.onhide}
        style={{
          fontFamily: "Poppins",
          fontSize: "12px",
          // color: "#00000080",
          // paddingTop: "15px",
        }}
        dialogClassName="rounded"
      >
        <Modal.Header closeButton style={{ border: "none" }}>
          <h6 className="w-100 text-center">
            <b>{t("s&k.1")}</b>
          </h6>
        </Modal.Header>
        <Modal.Body className="pt-1">
          <div
            dangerouslySetInnerHTML={{
              __html: props.isi
                .replaceAll(/<br>/g, "")
                .replaceAll(/<p><\/p>/g, "")
                .replaceAll(/<p>/g, "<p style='text-align: justify'>"),
            }}
          ></div>
          <Form.Check
            className="mt-2"
            id={"checkboxtnc"}
            type={"checkbox"}
            label={t("pernyataansetuju")}
            onChange={(event) => {
              props.setchecked(event.target.checked);
              mirroring(handlePayload(event), 999, zoomContext?.csId);
            }}
          />
          <Row className="pt-3 text-end">
            <Col>
              {/* <Link to="/formpembukaanakunnasabah"> */}
              <Button
                variant={"danger"}
                disabled={!props.checked}
                onClick={props.fungsilanjut}
              >
                {props.labelbuttonsetuju}
              </Button>
              {/* </Link> */}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export function Modalloading(props) {
  return (
    <>
      <Modal
        centered
        className="position-absolute"
        backdropClassName="bgputihtransparan position-absolute w-100 h-100"
        container={document.getElementById("flowPart")}
        enforceFocus={false}
        show={props.show}
        style={{ border: "0", background: "rgb(255 255 255 / 90%)" }}
        contentClassName="border-0 bg-transparent"
      >
        <img
          style={{ width: "10rem", height: "10rem", margin: "auto" }}
          src={require("../DipsGraphicMobileUI/bvicLoadingSpin150.gif")}
        ></img>
      </Modal>
    </>
  );
}

export function ModalFetchingData({ show }) {
  return (
    <>
      <Modal centered show={show}>
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <p style={{ textAlign: "center" }}>
              {t("mengambildata")}
            </p>
            <img
              style={{ maxWidth: "150px" }}
              src={require("../DipsGraphicMobileUI/bvicLoadingSpin150.gif")}
              alt="Loading..."
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export function ModalApiError({ show, onClick }) {
  return (
    <>
      <Modal centered show={show}>
        <Modal.Body>
          <div style={{ display: "block", textAlign: "center" }}>
            Gagal menghubungi server, silakan coba beberapa saat lagi atau
            hubungi Call Center kami ...
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            onClick={onClick}
          >
            Kembali
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export function Modalpopuptrxsukses(props) {
  /**
   props{show, onhide, srcicon, judul, isi, classbutton, fungsibutton, labelbutton}   
   */
  return (
    <>
      <Modal
        centered
        show={props.show}
        onHide={props.onhide}
        backdrop="static"
        style={{ fontFamily: "Poppins" }}
        dialogClassName="border-radius-2 w400 padlef50"
        className="text-center"
      >
        <div className="align-middle" style={{ margin: "10px 0" }}>
          {/* <Col> */}
          <img
            alt=""
            aria-label=""
            className="mx-auto"
            style={{ width: "78px", height: "80px", padding: "inherit" }}
            src={props.srcicon}
          ></img>
          {/* </Col> */}
        </div>
        <div
          className="lh-base"
          style={{
            color: "#00000080",
            fontSize: "12px",
            // position: "relative",
            // marginTop: "35px",
          }}
        >
          <div className="fw-bold h6 mb-2">{props.judul}</div>
          <div>{props.isi}</div>
        </div>
        <Modal.Footer style={{ border: "none" }} className="text-center">
          <div>
            <button className={props.classbutton} onClick={props.fungsibutton}>
              {props.labelbutton}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

async function compareswafoto(pic, props) {
  if (typeof pic === "string") {
    console.log(pic);
    await validasiSwafoto(pic).then(async (res) => {
      console.log(res, props.isOcr);

      if (res) {
        props.setShowloading(false);
        if (props.isOcr === true) {
          props.setStateocrktp(res.data);
          props.setOCRStatement(false);
          props.setNotifcekktp(true);
        } else {
          let image = await convertImageToFile(pic, "image.jpg");
          console.log(image);
          props.pasangimage(image);
        }
      } else {
        props.setShowloading(false);
        alert(t("KTP tidak terdeteksi, harap ulangi kembali"));
      }
    });
  }
  // return { output };
}

export function Modalswafoto(props) {
  const { t } = useTranslation();
  const [picture, setPicture] = useState();
  const webcamref = useRef(null);
  const capture = useCallback(() => {
    return webcamref.current.getScreenshot();
  }, [webcamref]);

  return (
    <Modal
      centered
      backdrop="static"
      size="lg"
      show={props.modalswafoto}
      onHide={() => {
        props.setModalswafoto(false);
        setPicture(null);
      }}
      style={{ fontFamily: "Poppins" }}
      dialogClassName="border-radius-2"
      className=""
    >
      <Modal.Header bsPrefix="border-0 text-center p-3 pb-0 d-flex">
        <h6 className="w-100">{t("swafoto")}</h6>
        <img
          onClick={() => {
            props.setModalswafoto(false);
            setPicture(null);
          }}
          className="position-absolute top-0 end-0 m-3 cursorpointer"
          style={{ height: "1rem" }}
          alt=""
          src={props.closeicon}
        ></img>
      </Modal.Header>
      <Modal.Body
        className="lh-base"
        style={{
          color: "#00000080",
          fontSize: "15px",
          // position: "relative",
          // marginTop: "35px",
        }}
      >
        <div className="text-center pb-3">
          <span>{t("descswafoto")}</span>
        </div>
        {props.modalswafoto === true && picture ? (
          <>
            <img
              style={{
                borderRadius: "20px",
                width: "-webkit-fill-available",
                height: "auto",
              }}
              src={picture}
              alt=""
            />
          </>
        ) : (
          <>
            <div style={{ position: "relative" }}>
              <Webcam
                id="webcam"
                autoPlay
                playsInline
                style={{
                  borderRadius: "20px",
                  width: "-webkit-fill-available",
                  height: "auto",
                  // transform: "scale(-1,1)",
                }}
                screenshotFormat="image/jpeg"
                mirrored={true}
                ref={webcamref}
              />
              {/* style={{transform:"scale(-1, 1)"}} => ini memberikan efek seperti menghadap cermin terhadap webcam */}
              <canvas id="canvas" className="d-none w-50 h-50"></canvas>
              {/* <center> */}
              <img
                style={{
                  position: "absolute",
                  width: "50%",
                  top: "50%",
                  left: "70%",
                  transform: "translate(-50%, -50%)",
                }}
                alt="171x180"
                src={require("../DipsGraphicMobileUI/framektp.png")}
              />
              {/* </center> */}
            </div>
          </>
        )}
        <div>
          <center>
            {picture ? (
              <Button
                // disabled={formState.length <= 0}
                className="m-3"
                onClick={async () => {
                  compareswafoto(picture, props);
                  setPicture(null);
                  props.setModalswafoto(false);
                }}
                variant="danger"
              >
                {t("lanjut")}
              </Button>
            ) : (
              <button
                className="border-0 bg-putih"
                onClick={() => {
                  setPicture(capture());
                  // console.log("picture:", capture());
                }}
              >
                <img
                  style={{ height: "3rem" }}
                  src={props.captureicon}
                  alt=""
                ></img>
              </button>
            )}
          </center>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export function ModalcekOCR(props) {
  const { t } = useTranslation();
  const stateocrinit = props.stateocrktp;
  const [stateocr, setStateocr] = useState(null);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setStateocr(props.stateocrktp);
  }, [props.stateocrktp]);

  // console.log({ stateocrinit, stateocr }, props.stateocrktp);

  // async function validasiIbuKandungOCR() {
  //   setValidatingIbuKandung(true);
  //   setShowloading(true);
  //   const {
  //     provinsi,
  //     kabupaten,
  //     kelurahandesa,
  //     kecamatan,
  //     noidentitas,
  //     namasesuaiidentitas,
  //     tempatlahir,
  //     tanggallahir,
  //     jeniskelamin,
  //     namaibukandung,
  //     alamatsesuaiidentitas,
  //     rt,
  //     rw,
  //     statusmenikah,
  //     pekerjaan,
  //   } = stateocrktp;

  //   console.log(authData);

  //   const payload = {
  //     idDips: authData?.idDips,
  //     propinsi: provinsi,
  //     kabupaten,
  //     nik: noidentitas,
  //     namaLengkap: namasesuaiidentitas,
  //     tempatlahir,
  //     tglLahir: tanggallahir,
  //     jenisKelamin: jeniskelamin,
  //     namaLengkapIbu: namaibukandung,
  //     alamat: alamatsesuaiidentitas,
  //     rt,
  //     rw,
  //     kelurahan: kelurahandesa,
  //     kecamatan,
  //     statusKawin: statusmenikah,
  //     jenisPekerjaan: pekerjaan,
  //   };
  //   validasiIbuKandung(payload).then(({ data, status }) => {
  //     if (status) {
  //       setShowloading(false);
  //       if (data.status === "oke") {
  //         setIbuKandungValidated(true);
  //       } else {
  //         if (data.message === "data tidak valid") {
  //           setOCRStatement(false);
  //           const idx = ktpErrorKeys.findIndex(
  //             (el) => el.keys === "namaibukandung"
  //           );
  //           if (idx >= 0) {
  //             const newValue = [...ktpErrorKeys];
  //             newValue[idx] = {
  //               key: "namaibukandung",
  //               errorMessage: "datatidaksesuai",
  //             };
  //             setErrorKeys(newValue);
  //           } else {
  //             setErrorKeys((prev) => {
  //               return [
  //                 ...prev,
  //                 { key: "namaibukandung", errorMessage: "datatidaksesuai" },
  //               ];
  //             });
  //           }
  //         } //reach maximum try
  //         else {
  //           setOCRStatement(false);
  //           setShowloading(true);
  //           alert(t("mohontunggu2menit"));
  //           setTimeout(() => {
  //             setShowloading(false);
  //           }, 120000);
  //         }
  //       }
  //     } else {
  //       setOCRStatement(false);
  //       setShowloading(false);
  //       const idx = ktpErrorKeys.findIndex(
  //         (el) => el.keys === "namaibukandung"
  //       );
  //       if (idx >= 0) {
  //         const newValue = [...ktpErrorKeys];
  //         newValue[idx] = {
  //           key: "namaibukandung",
  //           errorMessage: "failedToValidate",
  //         };
  //         setErrorKeys(newValue);
  //       } else {
  //         setErrorKeys((prev) => {
  //           return [
  //             ...prev,
  //             {
  //               key: "namaibukandung",
  //               errorMessage: "failedToValidate",
  //             },
  //           ];
  //         });
  //       }
  //     }
  //   });
  //   // setTimeout(() => {
  //   //   setIbuKandungValidated(true)
  //   // }, 3000)
  //   // await axios.post(`${process.env.REACT_APP_SERVER2}/api/customer-portfoio/validasi/`)
  // }

  return (
    <Modal
      centered
      backdrop="static"
      show={props.shownotifcekktp}
      onHide={() => {
        setChecked(false);
      }}
      style={{ fontFamily: "Poppins" }}
      // dialogClassName="border-radius-2"
      className="rounded"
    >
      <Row
        className="align-middle"
        style={{ width: "inherit", marginTop: "20px" }}
      >
        {/* <Col> */}
        <img
          className="mx-auto"
          style={{ width: "78px", height: "80px", padding: "inherit" }}
          src={require("../DipsGraphicMobileUI/Group 3559.png")}
          alt=""
        ></img>
        {/* </Col> */}
      </Row>
      <div
        // className="lh-base"
        style={{
          color: "#00000080",
          fontSize: "12px",
          // position: "relative",
          marginTop: "10px",
        }}
      >
        <center className="mb-1 fw-bold fs-6 justify-content-md-center">
          {t("tanyakesesuaiandataktp")}
        </center>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            paddingBottom: "20px",
            padding: "20px",
          }}
        >
          {stateocr &&
            Object.keys(stateocr).map((keyname, i) => {
              return (
                <div
                  style={{
                    width: window.innerWidth > 768 ? "50%" : "100%",
                    paddingBottom: "1px",
                    // textIndent: "1rem",
                    // paddingRight: i % 2 === 0 ? "10px" : "0",
                  }}
                  key={i}
                >
                  <div>
                    <span>{t(`ocr.${keyname}`)}</span>
                  </div>
                  {/* <input
                      className="p-1 w-100 border-0 rounded" style={{ backgroundColor: "#F0F3F8", paddingLeft: "5px" }}
                      value={stateocrktp[keyname]}
                    /> */}
                  <input
                    className="ps-2 w-100 border-0 rounded"
                    style={{ backgroundColor: "#F0F3F8", padding: "2px" }}
                    value={
                      stateocr[keyname] !== null ||
                        stateocr[keyname] !== undefined
                        ? stateocr[keyname]
                        : ""
                    }
                    onChange={(event) => {
                      setStateocr({
                        ...stateocr,
                        [keyname]: event.target.value,
                      });
                      // if (event.target.value !== "") {
                      //   const newValue = [...props.ktpErrorKeys];
                      //   const idx = newValue.findIndex(
                      //     (el) => el.key === keyname
                      //   );
                      //   // console.log(newValue);
                      //   // console.log(idx);
                      //   // console.log(keyname);
                      //   if (idx >= 0) {
                      //     newValue.splice(idx, 1);
                      //     console.log(newValue);
                      //     props.setErrorKeys(newValue);
                      //   }
                      // }
                    }}
                  />
                  {/* {props.ktpErrorKeys.find((el) => el.key === keyname) && (
                    <small style={{ color: "red" }}>{t(props.ktpErrorKeys.find((el) => el.key === keyname).errorMessage)}</small>
                  )} */}
                </div>
              );
            })}
          <label
            style={{
              display: "flex",
              alignItems: "center",
              paddingTop: "15px",
            }}
          >
            <input
              type="checkbox"
              checked={checked}
              onChange={(e) => {
                setChecked(e.target.checked);
                if (e.target.checked) {
                  // if (
                  //   Object.values(props.stateocrktp).every(
                  //     (val) => val && val !== ""
                  //   )
                  // ) validasiIbuKandungOCR();
                }
              }}
            />
            <div className="ms-2">{t("datasudahbenar")}</div>
          </label>
        </div>
      </div>
      <Modal.Footer
        style={{
          border: "none",
          justifyContent: "center",
          padding: ".75rem 0",
          paddingTop: "0",
        }}
      >
        <Button
          variant="outline-danger"
          style={{ fontSize: "12px", width: "45%" }}
          onClick={() => {
            setChecked(false);
            props.setNotifcekktp(false);
          }}
        >
          {t("batal")}
        </Button>
        <Button
          variant="danger"
          style={{
            fontSize: "12px",
            width: "45%",
          }}
          onClick={() => {
            props.setStateocrktp(stateocr);
            // setChecked(false);
            props.setNotifcekktp(false);
          }}
          disabled={!checked}
        >
          {t("lanjut")}
        </Button>
        {/* </Link> */}
      </Modal.Footer>
    </Modal>
  );
}

export function ModalConfirm({
  showmodal,
  fungsihide,
  fungsibuttonkiri,
  fungsibuttonkanan,
}) {
  return (
    <Modalbuatan
      show={showmodal}
      onhide={fungsihide}
      srcicon={require("../DipsGraphicMobileUI/i.png")}
      judul={""}
      isi={"Apakah Anda ingin melakukan panggilan kembali?"}
      classkiri={"bordermerahdalamputih btn-buatan w-100"}
      fungsikiri={fungsibuttonkiri}
      labelkiri={"tidak"}
      classkanan={"bordermerahdalammerah btn-buatan w-100"}
      fungsikanan={fungsibuttonkanan}
      labelkanan={t("ya")}
    />
  );
}

export function ModalUploadIdCard(props) {
  const { t } = useTranslation();
  // const [webcammudaheasy, setWebcammudaheasy] = useState();
  const [picture, setPicture] = useState();
  const webcamref = useRef(null);
  const capture = useCallback(() => {
    return webcamref.current.getScreenshot();
  }, [webcamref]);

  async function pasangimage(pic) {
    let reader = new FileReader();
    // File size check
    reader.readAsDataURL(pic);
    reader.onloadend = function () {
      // compareswafoto(reader.result, props);
      props.setShowloading(false);
      props.setModalUploadIdCard(false);
      props.pasangimage(pic);
      setPicture(null);
    };
  }

  // console.log("webcammudaheasy", webcammudaheasy);

  return (
    <Modal
      centered
      backdrop="static"
      size="lg"
      show={props.modalUploadIdCard}
      onHide={() => {
        props.setModalUploadIdCard(false);
        setPicture(null);
      }}
      style={{ fontFamily: "Poppins" }}
      dialogClassName="border-radius-2"
      className=""
    >
      <Modal.Header bsPrefix="border-0 text-center p-3 pb-0 d-flex">
        <h6 className="w-100">{t("ktp")}</h6>
        <img
          onClick={() => {
            props.setModalUploadIdCard(false);
            setPicture(null);
          }}
          className="position-absolute top-0 end-0 m-3 cursorpointer"
          style={{ height: "1rem" }}
          alt=""
          src={props.closeicon}
        ></img>
      </Modal.Header>
      <Modal.Body
        className="lh-base"
        style={{
          color: "#00000080",
          fontSize: "15px",
          // position: "relative",
          // marginTop: "35px",
        }}
      >
        <div className="text-center pb-3">
          <span>{t("descunggahktpviamodal")}</span>
        </div>
        {!picture ? (
          <label
            style={{
              width: "100%",
              aspectRatio: "16/9",
              border: "1px dashed slategrey",
              borderRadius: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#F0F3F8",
              gap: "20px",
              fontSize: "30px",
              maxHeight: "100%",
            }}
          >
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={(e) => {
                e.preventDefault();
                setPicture(e.target.files[0]);
              }}
            />
            <UploadIcon style={{ height: "2em" }} />
            <div style={{ color: "#B5B5B5" }}>Upload</div>
          </label>
        ) : (
          <label
            style={{
              width: "100%",
              aspectRatio: "16/9",
              border: "1px solid slategrey",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={(e) => {
                e.preventDefault();
                console.log(e.target);
                setPicture(e.target.files[0]);
              }}
            />
            <img
              src={URL.createObjectURL(picture)}
              style={{
                objectFit: "contain",
                maxWidth: "100%",
                maxHeight: "100%",
              }}
              alt=""
            />
          </label>
        )}
        <div>
          <center>
            <Button
              // disabled={formState.length <= 0}
              className="m-3"
              onClick={() => {
                pasangimage(picture);
              }}
              variant="danger"
              disabled={!picture}
            >
              {t("lanjut")}
            </Button>
          </center>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export function ModalGagal({ show, backFunction }) {
  return (
    <Modal
      centered
      className="position-absolute"
      backdropClassName="position-absolute w-100 h-100"
      container={document.getElementById("flowPart")}
      show={show}
      enforceFocus={false}
    >
      <Modal.Body className="d-flex flex-column gap-2 align-items-center">
        <img src={XImage} alt="" style={{ width: "4rem", height: "4rem" }} />
        <div>{t("Gagal menghubungi server")}</div>
        <Button variant="danger" onClick={backFunction}>
          {t("Kembali")}
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export function CustomeModal({ show, leftClickFunction, leftText, rightClickFunction, rightText, image, title, desc, type }) {
  return <Modal
    centered
    className="position-absolute"
    backdropClassName="position-absolute w-100 h-100"
    container={document.getElementById("flowPart")}
    show={show}
    enforceFocus={false}
  >
    <Modal.Body className="d-flex flex-column gap-2 align-items-center">
      {type === "error" ? (
        <img src={XImage} alt="" style={{ width: "4rem", height: "4rem" }} />
      ) : type === "success" ? (
        <img src={CeklistBerhasil} alt="" style={{ width: "4rem", height: "4rem" }} />
      ) : (
        <img src={image} alt="" style={{ width: "4rem", height: "4rem" }} />
      )}
      <div className="fw-bold ">{title}</div>
      <div className="mb-3 text-center">{desc}</div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', gap: "10px" }}>
        <Button hidden={!leftText} style={{ minWidth: "6rem" }} variant="outline-danger" onClick={leftClickFunction}>
          {leftText}
        </Button>
        <Button hidden={!rightText} style={{ minWidth: "6rem" }} variant="danger" onClick={rightClickFunction}>
          {rightText}
        </Button>
      </div>
    </Modal.Body>
  </Modal>
}
