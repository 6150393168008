import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../LanguageContext";
import { ZoomClientContext } from "../../ZoomClientContext";
import { getLabelFromKey } from "../../utils/FormatString";
import { objHandleChange } from "../../utils/handleChange";
import {
  getFormattedAccountValue,
  mirroringKey,
} from "../../utils/mirroringKey";
import backArrow from "../DipsGraphicMobileUI/Arrowback.png";
import FormElementsPhase2 from "../FormElements/FormElementsPhase2";
import { useGetFormBuilder } from "../apiaxios";
import mirror from "../mirroring";
import { FaCamera, FaFileUpload } from "react-icons/fa";
import { AuthData } from "../../AuthData";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Modalloading } from "../komponen/Modal";

function AktivasiDormant({
  state,
  setConferenceRoute,
  previousConferenceRoute,
}) {
  const { zoomContext } = useContext(ZoomClientContext);
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();
  const {authData} = useContext(AuthData)

  const [idForm, setIdForm] = useState(null);
  const { 
    data, isLoading, isError,  } = useGetFormBuilder(idForm);

  const [preview, setPreview] = useState("")
  const [fileName, setFileName] = useState("")
  const [img, setImg] = useState({ file: null })
  const [TNCCheckBox, setTNCCheckBox] = useState(false);
  const labelTNC = "Dengan ini meminta kepada Bank untuk melakukan pengaktifan atas rekening Saya/Kami yang telah menjadi rekening tidak aktif (dormant account). Saya/Kami dengan ini membebaskan Bank dari segala tuntutan, gugatan dan ganti rugi dari pihak manapun yang timbul di kemudian hari sehubungan dengan pengaktifan rekening tidak aktif tersebut."
  // const [listAccountData, setListAccountData] = useState(null)

  const {
    isLoading: isLoadingList,
    data: listAccountData,
    isError: isErrorList,
    error: errorList,
    isSuccess: isSuccessList,
  } = useQuery({
      queryKey: ["active"],
      queryFn: async () => {
          console.log("GETTINGG");
          try {
              const res = await axios.post(
                  `${process.env.REACT_APP_SERVER2}/api/list-account/active`,
                  {
                      noCif: authData?.noCif,
                  }
              );
              return res?.data?.data;
          } catch (error) {
          // setStatusGetPorto(t("gagalmengambildata"));
              console.error("axios error:", error);
              throw error;
          }
      },
  });

  useEffect(() => {
    // fetch(`${process.env.REACT_APP_SERVER2}/api/list-account/active`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     noCif: authData?.noCif,
    //   }),
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     setListAccountData(data?.data);
    //     // console.log(data)
    //   })
    //   .catch(error => {
    //     console.error('Error:', error);
    //   });

      if (listAccountData && listAccountData.length > 1) {
        console.log("lebih dari 1")
        setIdForm(84)
        state.idform = 84
      } else if (listAccountData && listAccountData.length < 1) {
        console.log("kurang dari 1")
        setIdForm(96)
        state.idform = 96
      }
  }, [listAccountData])

  console.log(listAccountData)  

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log(file)

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreview(reader.result);
        setImg({file: file});
      };

      reader.readAsDataURL(file);
    }
  };

  const { values, errors, touched, ...formik } = useFormik({
    initialValues: data ? getLabelFromKey(data) : {},
    onSubmit: async (result) => {
      console.log("result", result);
      result.tnc = TNCCheckBox
      result.ttd = preview

      mirror(
        {
          [state?.mirrorKey]: mirroringKey(
            {
              ...result,
            },
            false,
            language
          ),
        },
        state?.code,
        zoomContext?.csId
      );
      let modState = { ...state };
      modState.dataState = [
        Object.fromEntries(
          Object.entries({ ...result }).map(([key, value]) => {
            return [
              key,
              typeof value === "object" && value
                ? getFormattedAccountValue(value, language)
                : value,
            ];
          })
        ),
      ];
      modState.actualState = [result];
      setConferenceRoute({ pathname: "transactionOTP", state: modState });
    },
    validate: (values) => {
      const errors = {};

      let nominalsetoran = values.nominalsetoran;
      if (nominalsetoran) {
        nominalsetoran = nominalsetoran.replace(/\./g, "");
        nominalsetoran = parseInt(nominalsetoran);
        if (nominalsetoran < (50000 || 0)) {
          errors.nominalsetoran = t('Minimal nominal setoran Rp. 50.000');
          // errors.nominalsetoran = undefined;
        } 
      }
      return errors;
    },
  });

  const onBlur = () => {
    mirror(
      {
        [state?.mirrorKey]: mirroringKey(
          {
            ...values,
            tnc: TNCCheckBox,
            ttd: preview.indexOf(',') !== -1 ? preview.split(',')[1] : preview
          },
          false,
          language
        ),
      },
      state?.code,
      zoomContext?.csId
    );
  };

  function handleChange(e, key, i, data) {
    const newValue = objHandleChange(e, key, data, values);
    formik.setValues(newValue);

    // if (newValue.nomorrekening) {
    //   formik.setFieldValue('namasesuaiidentitas', newValue?.nomorrekening?.accountName)
    // } else {
    //   formik.setFieldValue('namasesuaiidentitas', '-')
    // }

    // if (key === 'nominalsetoran' && parseInt(newValue.nominalsetoran.replace(/\./g, "")) >= 50000) {
    //   formik.setErrors({ ...formik.errors, nominalsetoran: undefined });
    // }
    if (key === 'nomorrekening') {
      if (!newValue.nomorrekening) {
        formik.setFieldValue('namasesuaiidentitas', '-')
      } else {
        formik.setFieldValue('namasesuaiidentitas', newValue?.nomorrekening?.accountName)
      }
    }
    
    if (key === 'nominalsetoran') {
      const nominalsetoran = parseInt(newValue.nominalsetoran.replace(/\./g, ""));
      if (nominalsetoran >= (50000 || 0)) {
        formik.setErrors({ ...formik.errors, nominalsetoran: undefined });
      } else {
        formik.setErrors({ ...formik.errors, nominalsetoran: t('Minimal nominal setoran Rp. 50.000') });
      }
    }
    

    console.log(newValue)
  }

  useEffect(() => {
    formik.setFieldValue('namasesuaiidentitas', '-')
  },[])

  function isButtonDisabled() {
    if (TNCCheckBox === false || preview === "" || (values.nominalsetoran && parseInt(values.nominalsetoran.replace(/\./g, "")) < 50000) || values.namasesuaiidentitas === "-") {
      return true
    }
    if (data) {
      const flatFormField = data.flatMap((el) => el.components);
      const requiredKeys = flatFormField.map((val) => {
        if (val?.props?.required) {
          return val.label.labelIdn.toLowerCase().replace(/[ ./-]/g, "");
        }
        return;
      });
      return !requiredKeys.every(
        (key) => values?.[key] && values?.[key] !== ""
      );
    } else {
      return true;
    }
  }

  return (
    <>
      <div
        className="p-2 w-100 h-100 bordergreyfull bgimagebatikfullscreen d-flex flex-column"
        style={{ overflow: "hidden auto" }}
        id="container-transaksi"
      >
        <div
          className="d-flex align-items-center px-3 mt-2"
          style={{ fontSize: "20px", gap: "10px" }}
        >
          <img
            src={backArrow}
            alt=""
            style={{ width: "20px", marginRight: "10px", cursor: "pointer" }}
            onClick={() =>
              setConferenceRoute({ pathname: "customerproduct", state: state })
            }
          />
          <div style={{ fontSize: "20px" }}>
            {t(state?.title.replace(/ /g, "").toLowerCase())}
          </div>
        </div>
        <hr className="border-2 border-secondary border-top w-100 my-2" />
        {data && !isLoading && !isError && (
          <>
            <FormElementsPhase2
              formState={values}
              stateArray={[values]}
              index={0}
              errorState={[errors]}
              data={data}
              handleFormChange={handleChange}
              handleFormBlur={onBlur}
            />
            <div 
                className='my-3 ps-3 d-flex align-items-center'
                style={{
                    backgroundColor: '#FDE5D9',
                    height: '3.5rem',
                    borderRadius: '5px',
                    paddingBottom: '15px',
                    paddingTop: '15px',
                }}
            >
                {t("Minimum transaksi yang harus dilakukan sebesar Rp. 50.000,00 dan transaksi harus dilakukan di jam operasional")}
            </div>
            <div className='row'>
                <div className='col-12'>
                    <Form.Check
                        type="checkbox"
                        checked={TNCCheckBox}
                        onChange={() => setTNCCheckBox(!TNCCheckBox)}
                        onBlur={onBlur}
                        // style={{ width: "20px", aspectRatio: "1/1" }}
                        label={t(labelTNC)}
                        id="TNC"
                    />
                </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <label 
                htmlFor="ttd" className="form-label" style={{ fontWeight: 'semibold' }}
              >
                {t("tandatangan")}
              </label>
              <div 
                  className="box-input-receipt d-flex justify-content-center align-items-center flex-column px-3"
                  style={{
                      border: "2px dashed #d4d4d4",
                      borderRadius: "7px",
                      height: img?.file ? "auto" : "16rem",
                      paddingTop: img?.file ? "1.7rem" : "",
                      paddingBottom: img?.file ? "1.7rem" : "",
                      cursor: "pointer",
                      backgroundColor: "#eee",
                      width: '35%',
                  }}
                  onClick={() => document.querySelector('#ttd').click()}
              >
                  <input 
                      className="form-control" 
                      type="file" 
                      id="ttd"
                      // accept='image/*'
                      hidden
                      onBlur={onBlur}
                      onChange={handleImageChange}
                  />

                  {preview ? (
                      <img src={preview} width={"250px"} className='img-fluid' alt={fileName} />
                  ):(
                      <div style={{ marginTop: 20, marginBottom: 20, display: "flex", justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                          <FaCamera color='#B5B5B5' size={60} />
                          <div className='d-flex align-items-center gap-2'>
                              <span style={{borderTop: '1.5px solid #B5B5B5', width: "70px"}}></span>
                              <p style={{fontSize: "12px", color: "#B5B5B5"}} className='my-2'>{t("atau")}</p>
                              <span style={{borderTop: '1.5px solid #B5B5B5', width: "70px"}}></span>
                          </div>
                          <div className='d-flex align-items-center'>
                              <FaFileUpload color='#B5B5B5' />&nbsp;
                              <p style={{fontSize: "14px", color: "#B5B5B5"}}>{t("pilihdarigaleri")}</p>
                          </div>
                      </div>
                  )}
              </div>
            </div>
            {errors.nominalsetoran && touched.nominalsetoran && (
                <div>{errors.nominalsetoran}</div>
            )}
            <div className="d-flex justify-content-end gap-3">
              <Button
                disabled={isButtonDisabled()}
                onClick={() => formik.handleSubmit()}
                variant="danger"
              >
                {t("ajukan")}
              </Button>
            </div>
          </>
        )}
      </div>
      <Modalloading show={isLoading} />
    </>
  );
}

export default AktivasiDormant;