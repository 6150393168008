import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import { lazy, Suspense, useState } from "react";
import { useEffect } from "react";
import { AuthData } from "./AuthData";
import { PosisiAlamatBerbeda } from "./PosisiAlamatBerbeda";
import { RequiredForm } from "./RequiredForm";
import { CloseVideo } from "./CloseVideo";
import { LanguageContext } from "./LanguageContext";
import { TranslateInstance } from "./i18n/config";
import { ZoomClientContext } from "./ZoomClientContext";
import { DataOcrKtpContext } from "./DataOcrKtpContext";
import { Npwp } from "./Npwp";
import mirror from "./page/mirroring";
import { client } from "./utils/rabbitMQ2";
import axios from "axios";
import AktivasiDormantMenu from "./page/AktivasiDormant/AktivasiDormant";
// import Pengecekandata from "./page/Pengecekandata";

const DalamPengerjaan = lazy(() => import("./page/DalamPengerjaan"));
const TransisiWaitingroom = lazy(() => import("./page/TransisiWaitingroom"));
const Alternativeflow = lazy(() => import("./page/alternativeflow"));
const Swafoto = lazy(() => import("./page/swafoto"));
const WaitingRoom = lazy(() => import("./page/WaitingRoom"));
const WaitingAds = lazy(() => import("./page/waitingads"));
const WaitingProduct = lazy(() => import("./page/waitingproduct"));
const ProductInfo2 = lazy(() => import("./page/productinfo2"));
const Welcome = lazy(() => import("./page/welcome"));
const Rating2 = lazy(() => import("./page/rating2"));
// const Rating = lazy(() => import("./page/rating"));
const Unggahktpawal = lazy(() => import("./page/unggahktpawal"));
const Pilihbahasa = lazy(() => import("./page/pilihbahasa"));
const Userdatacheckingpage = lazy(() => import("./page/userdatacheckingpage"));
const Disclaimerdips = lazy(() => import("./page/disclaimerdips"));
const OutsideTransaction = lazy(() => import("./page/OutsideTransaction"));


function App() {
  const [signatureId, setSignatureId] = useState("");
  const [authData, setAuthData] = useState({
    id: 301,
    namaLengkap: "MOHAMMAD RAFII BURHANUDDIN",
    gelar: null,
    alamat: "JL. GANESHA TENGAH NO.84",
    noHp: "081215702727",
    idDips: "sz76KGMmz3N0lghG",
    noCif: "51691813",
    email: "MOHAMMADRAFII480@GMAIL.COM",
    nik: "6201054410990003",
    jenisKelamin: "LAKI-LAKI",
    foto: "/home/hadi/dips/customer/faceData/foto-sz76KGMmz3N0lghG.png",
    isSwafoto: true,
    meta: '{"filename":"foto-sz76KGMmz3N0lghG.png"}',
    blacklist: false,
    branchCode: "020",
    createdAt: "2023-07-12T09:42:59.572Z",
    updatedAt: "2023-11-14T17:22:38.042Z",
  });
  const [language, setLanguage] = useState("Idn");
  const [zoomContext, setZoomContext] = useState(null);
  const [posisiAlamatBerbeda, setPosisiAlamatBerbeda] = useState(null);
  const [requiredForm, setRequiredForm] = useState(null);
  const [closeVideo, setCloseVideo] = useState(null);
  const [npwp, setNpwp] = useState("");
  const [dataOcrKtpContext, setDataOcrKtpContext] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (!client.active) {
      // console.log("jalan")
      client.activate();
    }
    // return () => {
    //   console.log('matikan')
    //   client.deactivate()
    // }
    if (!authData) {
      mirror({}, 99, zoomContext?.csId);
      navigate("/");
    }
    return () => {
      window.top && window.top.postMessage({ data: "END", age: 10 });
      mirror({}, 99, zoomContext?.csId);
    };
  }, []);
  useEffect(() => {
    TranslateInstance(language);
  }, [language]);

  useEffect(() => {}, [zoomContext]);

  const [JWT, setJWT] = useState({
    token: null,
    exchange: null,
  });

  useEffect(() => {
    // console.log(JWT);
  }, [JWT]);

  axios.interceptors.response.use(
    function (response) {
      try {
        if (
          response.headers["content-type"] === "application/json; charset=utf-8"
        ) {
          //"image/jpeg" //"application/json; charset=utf-8"
          if (response.data.data.token) {
            setJWT(response.data.data.token);
            // setJWT({ ...JWT, exchange: response.data.data.token.exchange });
          } else if (response.data.token) {
            // setJWT({ ...JWT, token: response.data.token });
            setJWT(response.data.token);
          }
        }
        return response;
      } catch (err) {
        console.log("ERRORRRRR", err);
        return response;
      }
    },
    async function (error) {
      const { config, message } = error;
      // console.log(config);
      if (config && config.retry) {
        config.retry -= 1;
        const delayRetryRequest = new Promise((resolve) => {
          setTimeout(() => {
            console.log("retry the request", config.url);
            resolve();
          }, config.retryDelay || 1000);
        });
        await delayRetryRequest;
        return await axios(config);
      }
      if (error.status === 401) {
        alert();
        window.location.href = `${process.env.REACT_APP_BASENAME}`;
      }
      return Promise.reject(error);
    }
  );

  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      config.headers["Authorization"] = "Bearer ".concat(JWT?.token);
      config.headers["exchangeToken"] = JWT?.exchange || "";
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  return (
    <Suspense fallback={<div></div>}>
      <AuthData.Provider value={{ authData, setAuthData }}>
        <LanguageContext.Provider value={{ language, setLanguage }}>
          <ZoomClientContext.Provider value={{ zoomContext, setZoomContext }}>
            <PosisiAlamatBerbeda.Provider
              value={{ posisiAlamatBerbeda, setPosisiAlamatBerbeda }}
            >
              <RequiredForm.Provider value={{ requiredForm, setRequiredForm }}>
                <CloseVideo.Provider value={{ closeVideo, setCloseVideo }}>
                  <Npwp.Provider value={{ npwp, setNpwp }}>
                    <DataOcrKtpContext.Provider
                      value={{ dataOcrKtpContext, setDataOcrKtpContext }}
                    >
                      <Routes>
                        <Route
                          exact
                          path="/unavailable"
                          element={<DalamPengerjaan />}
                        />
                        
                        <Route
                          exact
                          path="/menuD"
                          element={<AktivasiDormantMenu />}
                        />

                        <Route
                          exact
                          path="/welcome"
                          element={
                            <Welcome
                              setSignatureId={setSignatureId}
                              signatureId={signatureId}
                            />
                          }
                        />
                        <Route exact path="/" element={<Pilihbahasa />} />
                        <Route
                          exact
                          path="/transisiwaitingroom"
                          element={<TransisiWaitingroom />}
                        />
                        <Route
                          exact
                          path="/waitingroom"
                          element={<WaitingRoom />}
                        />

                        <Route
                          exact
                          path="/alternativeflow"
                          element={<Alternativeflow />}
                        />

                        {/* <Route exact path="/rating" element={<Rating />} /> */}
                        <Route exact path="/rating2" element={<Rating2 />} />
                        {/* <Route exact path="/formbukarekening" element={<FormBukaRekening />} /> */}
                        <Route
                          exact
                          path="/pilihbahasa"
                          element={<Pilihbahasa />}
                        />

                        <Route
                          path="/conference"
                          element={<Userdatacheckingpage />}
                        />
                        <Route
                          path="/disclaimerdips"
                          element={<Disclaimerdips />}
                        />

                        <Route
                          path="/outsidetransaction"
                          element={<OutsideTransaction />}
                        />

                        <Route path="*" element={<DalamPengerjaan />} />
                      </Routes>
                    </DataOcrKtpContext.Provider>
                  </Npwp.Provider>
                </CloseVideo.Provider>
              </RequiredForm.Provider>
            </PosisiAlamatBerbeda.Provider>
          </ZoomClientContext.Provider>
        </LanguageContext.Provider>
      </AuthData.Provider>
    </Suspense>
  );
}

export default App;
