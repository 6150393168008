export function arrayHandleChange(e, key, i, data, array) {
  if (data?.name === "Currency") {
    function formatCurrency(inputValue) {
      const formatter = new Intl.NumberFormat("id-ID");
      const formattedValue = formatter.format(inputValue);
      return formattedValue;
    }
    const inputValue = e.target.value;
    const parsedValue = inputValue
      .replace(/[^\d,]/g, "")
      .replace(/^(\d*\.?\d{0,2}).*$/, "$1");

    const newValue = [...array];
    newValue[i][key] = formatCurrency(parsedValue);
    return newValue;
  } else {
    const newValue = [...array];
    newValue[i][key] = e.target.value;
    return newValue;
  }
}

export function objHandleChange(e, key, data, obj) {
  if (data?.name === "Currency") {
    // Function to format currency
    function formatCurrency(inputValue) {
      const formatter = new Intl.NumberFormat("id-ID");
      const formattedValue = formatter.format(inputValue);
      return formattedValue;
    }

    // Extract input value from event object
    const inputValue = e.target.value;

    // Parse and format the currency value
    const parsedValue = inputValue
      .replace(/[^\d,]/g, "") // Remove non-digit and non-comma characters
      .replace(/^(\d*\.?\d{0,2}).*$/, "$1"); // Ensure up to two decimal places

    // Create a copy of the object to avoid mutation
    const newObj = { ...obj };

    // Update the specified key with formatted currency value
    newObj[key] = formatCurrency(parsedValue);

    // Return the updated object
    return newObj;
  } else {
    // Create a copy of the object to avoid mutation
    const newObj = { ...obj };
    
    console.log(obj);

    // Update the specified key with raw input value
    newObj[key] = e.target.value;

    // Return the updated object
    return newObj;
  }
}
