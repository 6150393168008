import { Client } from "@stomp/stompjs";

export const client = new Client({
    brokerURL: "wss://dips.grit.id:15673/ws",
      connectHeaders: {
        login: "dips361",
        passcode: "dips2022",
      },
      onConnect: (frame) => {
        if (frame.command === "CONNECTED") {
            console.log("connected")
        }
      },
      onDisconnect: (frame) => {
        console.log(frame, "disconnect")
      },
      reconnectDelay: 5000,
      onStompError: (err) => {
        console.log(err, "stomp error")
      },
      onWebSocketError: (err) => {
        console.log(err, "web socket error")
      },
      // debug: (str) => {
      //   console.log(str, "debug")
      // }
})

export function publish(dest, body) {
    console.log("publish")
    client.publish(dest, body);
}

export function listen(dest) {
    console.log("listen")
    client.subscribe(dest, (msg) => {
        console.log(msg)
        return msg
    })
}