import { useEffect, useRef, useState } from "react";
import { Form, Col, InputGroup, Card, Button } from "react-bootstrap";
import axios from "axios";
import { ArrowDown } from "../DipsGraphicMobileUI/ArrowDown";
import moment from "moment/moment";
import { useContext } from "react";
import { AuthData } from "../../AuthData";
import { LanguageContext } from "../../LanguageContext";
import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import {MdDelete} from "react-icons/md"
import { FaEye, FaEyeSlash } from "react-icons/fa";

export const AutoComplete = ({
  data,
  altLabel,
  errorState,
  formState,
  handleFormChange,
  handleFormBlur,
  index,
}) => {
  const rekeningField =
    data?.label?.labelIdnLowerCase === "rekeningpenerima" || false;

  const [typeInput, setTypeInput] = useState({});
  useEffect(() => {
    setTypeInput({
      ...typeInput,
      [data?.label?.labelIdnLowerCase]: { type: data?.type },
    });
  }, []);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Form.Group
      as={Col}
      className="mb-3"
      style={
        data.props.hidden
          ? { flexDirection: "column", display: "none" }
          : { flexDirection: "column" }
      }
    >
      <Form.Label
        style={{
          minHeight: "1.5rem",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: "5px" }}>
          {data?.label?.[`label${data.bahasa}`]
            ?.replace("(Wajib diisi)", "")
            ?.replace("(Mandatory)", "")}
        </div>
        <div style={{ fontSize: "12px", fontWeight: "" }}>
          {data.props && data.props.required
            ? data.bahasa === "Idn"
              ? " (Wajib diisi)"
              : " (Mandatory)"
            : null}
        </div>
      </Form.Label>
      {!["mpin", "konfirmasimpin"].includes(
        data?.label?.labelIdn.toLowerCase().replace(" ", "")
      ) ? (
        <>
          <Form.Control
          type={showPassword ? "text" : data.type}
            placeholder={data.placeholder[`placeholder${data.bahasa}`]}
            required={data.props && data.props.required}
            disabled={data.props && data.props.disabled}
            maxLength={data?.props?.maxLength}
            value={
              formState[data.label[`label${data.bahasa}LowerCase`]] ||
              formState[altLabel] ||
              ""
            }
            pattern={typeInput?.[data?.label?.labelIdnLowerCase]?.type === "password" ? "^[0-9]*$" : undefined}
            className="formBuilder2"
            onBlur={(e) => {
              typeof handleFormBlur === "function" &&
                handleFormBlur(
                  e,
                  data.label.labelIdnLowerCase
                    ? data.label.labelIdnLowerCase
                    : altLabel
                );
            }}
            onChange={(e) => {
              if (
                (
                  (
                    (
                      data?.label?.labelIdnLowerCase === "pinlama" || 
                      data?.label?.labelIdnLowerCase === "pinbaru" || 
                      data?.label?.labelIdnLowerCase === "konfirmasipinbaru"
                    ) && typeInput?.[data?.label?.labelIdnLowerCase]?.type === "password"
                  ) || (
                    data?.label?.labelIdnLowerCase === "nomorkartu" || 
                    data?.label?.labelIdnLowerCase === "nokartuatm" ||
                    data?.label?.labelIdnLowerCase === "norekeningtujuan")
                ) && /\D/.test(e.target.value)
              ) {
                e.preventDefault();
              } else {
                handleFormChange(
                  e,
                  data.label.labelIdnLowerCase
                    ? data.label.labelIdnLowerCase
                    : altLabel,
                  index,
                  data
                );
              }
            }}
          ></Form.Control>
          {(data?.label?.labelIdnLowerCase === "pinlama" || data?.label?.labelIdnLowerCase === "pinbaru" || data?.label?.labelIdnLowerCase === "konfirmasipinbaru") && typeInput?.[data?.label?.labelIdnLowerCase]?.type === "password" ? (
            <div className="d-flex justify-content-end">
              {showPassword ? (
                <FaEyeSlash style={{marginTop: "-1.6rem", marginRight: "1.2rem", cursor: "pointer"}} className="passwordToggle text-muted" onClick={() => setShowPassword(!showPassword)} />
              ):(
                <FaEye style={{marginTop: "-1.6rem", marginRight: "1.2rem", cursor: "pointer"}} className="passwordToggle text-muted" onClick={() => setShowPassword(!showPassword)} />
              )}
            </div>
          ):(
            <></>
          )}
        </>
      ) : (
        <InputGroup>
          <Form.Control
            type={typeInput?.[data?.label?.labelIdnLowerCase]?.type}
            placeholder={data.placeholder[`placeholder${data.bahasa}`]}
            required={data.props && data.props.required}
            disabled={data.props && data.props.disabled}
            value={
              formState[data.label[`label${data.bahasa}LowerCase`]] ||
              formState[altLabel] ||
              ""
            }
            className="formBuilder2"
            onBlur={(e) => {
              typeof handleFormBlur === "function" &&
                handleFormBlur(
                  e,
                  data.label.labelIdnLowerCase
                    ? data.label.labelIdnLowerCase
                    : altLabel
                );
            }}
            onChange={(e) => {
              handleFormChange(
                e,
                data.label.labelIdnLowerCase
                  ? data.label.labelIdnLowerCase
                  : altLabel,
                index,
                data
              );
            }}
          ></Form.Control>
          <InputGroup.Text
            id="basic-addon2"
            onClick={() => {
              if (
                typeInput?.[data?.label?.labelIdnLowerCase]?.type === "password"
              ) {
                setTypeInput({
                  ...typeInput,
                  [data?.label?.labelIdnLowerCase]: { type: "text" },
                });
              } else {
                setTypeInput({
                  ...typeInput,
                  [data?.label?.labelIdnLowerCase]: { type: "password" },
                });
              }
            }}
          >
            {typeInput?.[data?.label?.labelIdnLowerCase]?.type ===
            "password" ? (
              <AiFillEye />
            ) : (
              <AiFillEyeInvisible />
            )}
          </InputGroup.Text>
        </InputGroup>
      )}
      {/* {rekeningField &&
        formState?.rekeningpenerima &&
        formState?.rekeningpenerima ===
        formState?.rekeningsumberdana?.accountNo && (
          <Form.Text className="text-danger d-block">
            *Rekening penerima tidak boleh sama dengan sumber dana
          </Form.Text>
        )} */}
      {errorState?.[index]?.[
        data?.label[`labelIdnLowerCase`] || formState?.[altLabel]
      ] && (
        <Form.Text className="text-danger d-block">
          {
            errorState?.[index]?.[
              data?.label[`labelIdnLowerCase`] || formState?.[altLabel]
            ]
          }
        </Form.Text>
      )}
    </Form.Group>
  );
};

export const PhoneNumber = ({
  data,
  altLabel,
  errorState,
  formState,
  handleFormChange,
  handleFormBlur,
  index,
}) => {
  const { t } = useTranslation();
  // const account =
  //   form &&
  //   form
  //     .flatMap((el) => el.components)
  //     .find((comp) => comp.name === "DropdownSumberDana")?.label?.labelIdn;
  // const accountData = account && account.toLowerCase()?.replace(/[ ./-]/g, "");

  // const modifiedValue = +formState?.[
  //   data?.label?.[`labelIdnLowerCase`]
  // ]?.replace(/[,.]/g, "");

  // useEffect(() => {
  //   if (setIsValid) {
  //     if (+formState?.[accountData]?.availBalance < (modifiedValue || 0)) {
  //       setIsValid(false);
  //     } else {
  //       setIsValid(true);
  //     }
  //   }
  // }, [formState?.[accountData], modifiedValue]);

  return (
    <Form.Group as={Col} className="mb-3">
      <Form.Label
        style={{
          minHeight: "1.5rem",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: "5px" }}>
          {data?.label?.[`label${data?.bahasa}`]
            ?.replace("(Wajib diisi)", "")
            ?.replace("(Mandatory)", "")}
        </div>
        <div style={{ fontSize: "12px", fontWeight: "" }}>
          {data?.props && data?.props.required
            ? data?.bahasa === "Idn"
              ? " (Wajib diisi)"
              : " (Mandatory)"
            : null}
        </div>
      </Form.Label>
      <InputGroup>
        <InputGroup.Text style={{ backgroundColor: "#50A0FF", color: "white" }}>
          {data?.phonecode.prefix}
        </InputGroup.Text>
        <Form.Control
          type={data?.type || "text"}
          placeholder={data?.placeholder?.[`placeholder${data?.bahasa}`] || ""}
          required={data?.props && data?.props.required}
          disabled={data?.props && data?.props.disabled}
          value={
            formState?.[
              data?.label[`labelIdnLowerCase`] || formState?.[altLabel]
            ] || ""
          }
          className="formBuilder2"
          onBlur={(e) => {
            typeof handleFormBlur === "function" &&
              handleFormBlur(
                e,
                data?.label?.labelIdnLowerCase
                  ? data?.label.labelIdnLowerCase
                  : altLabel || ""
              );
          }}
          onChange={(e) => {
            handleFormChange(
              e,
              data?.label?.labelIdnLowerCase
                ? data?.label.labelIdnLowerCase
                : altLabel || "",
              index,
              data
            );
          }}
        />
      </InputGroup>
      {/* {+formState?.[accountData]?.availBalance < (modifiedValue || 0) && (
        <Form.Text className="text-danger d-block">
          {t('nominalmelebihisumberdana')}
        </Form.Text>
      )}
      {errorState?.[index]?.[
        data?.label[`labelIdnLowerCase`] || formState?.[altLabel]
      ] && (
        <Form.Text className="text-danger d-block">
          {
            errorState?.[index]?.[
              data?.label[`labelIdnLowerCase`] || formState?.[altLabel]
            ]
          }
        </Form.Text>
      )} */}
    </Form.Group>
  );
};

export const Currency = ({
  data,
  altLabel,
  errorState,
  formState,
  handleFormChange,
  handleFormBlur,
  index,
  form,
  setIsValid,
}) => {
  const { t } = useTranslation();
  const account =
    form &&
    form
      .flatMap((el) => el.components)
      .find((comp) => comp.name === "DropdownSumberDana")?.label?.labelIdn;
  const accountData = account && account.toLowerCase()?.replace(/[ ./-]/g, "");

  const modifiedValue = +formState?.[
    data?.label?.[`labelIdnLowerCase`]
  ]?.replace(/[,.]/g, "");

  useEffect(() => {
    if (setIsValid) {
      if (+formState?.[accountData]?.availBalance < (modifiedValue || 0)) {
        setIsValid(false);
      // } else if (+modifiedValue < (50000 || 0)) {
      //   setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  }, [formState?.[accountData], modifiedValue]);

  return (
    <Form.Group as={Col} className="mb-3">
      <Form.Label
        style={{
          minHeight: "1.5rem",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: "5px" }}>
          {data?.label?.[`label${data?.bahasa}`]
            ?.replace("(Wajib diisi)", "")
            ?.replace("(Mandatory)", "")}
        </div>
        <div style={{ fontSize: "12px", fontWeight: "" }}>
          {data?.props && data?.props.required
            ? data?.bahasa === "Idn"
              ? " (Wajib diisi)"
              : " (Mandatory)"
            : null}
        </div>
      </Form.Label>
      <InputGroup>
        <InputGroup.Text style={{ backgroundColor: "#50A0FF", color: "white" }}>
          {data?.currency.prefix}
        </InputGroup.Text>
        <Form.Control
          type={data?.type || "text"}
          placeholder={data?.placeholder?.[`placeholder${data?.bahasa}`] || ""}
          required={data?.props && data?.props.required}
          disabled={data?.props && data?.props.disabled}
          value={
            formState?.[
              data?.label[`labelIdnLowerCase`] || formState?.[altLabel]
            ] || ""
          }
          className="formBuilder2"
          onBlur={(e) => {
            typeof handleFormBlur === "function" &&
              handleFormBlur(
                e,
                data?.label?.labelIdnLowerCase
                  ? data?.label.labelIdnLowerCase
                  : altLabel || ""
              );
          }}
          onChange={(e) => {
            handleFormChange(
              e,
              data?.label?.labelIdnLowerCase
                ? data?.label.labelIdnLowerCase
                : altLabel || "",
              index,
              data
            );
          }}
        />
      </InputGroup>
      {+formState?.[accountData]?.availBalance < (modifiedValue || 0) && (
        <Form.Text className="text-danger d-block">
          {t("nominalmelebihisumberdana")}
        </Form.Text>
      )}
      {/* {+modifiedValue < 50000 && (
        <Form.Text className="text-danger d-block">
          {t("Minimal nominal setoran Rp. 50.000")}
        </Form.Text>
      )} */}
      {errorState?.[index]?.[
        data?.label[`labelIdnLowerCase`] || formState?.[altLabel]
      ] && (
        <Form.Text className="text-danger d-block">
          {
            errorState?.[index]?.[
              data?.label[`labelIdnLowerCase`] || formState?.[altLabel]
            ]
          }
        </Form.Text>
      )}
    </Form.Group>
  );
};

export const Dropdown = ({
  data,
  altLabel,
  errorState,
  formState,
  handleFormChange,
  handleFormBlur,
  index,
}) => {
  const [dataFromURL, setDataFromURL] = useState(null);
  const [localState, setLocalState] = useState(null);
  const [fetching, setFetching] = useState(false);
  const { language } = useContext(LanguageContext);
  const { authData } = useContext(AuthData);

  const isListAccount = ["list-account/list", "list-account/dormant"].some(
    (path) => data?.url.includes(path)
  );

  useEffect(() => {
    let isMounted = true;
    const getFromURL = async () => {
      const timeout = setTimeout(() => {
        setFetching(true);
      }, 300);
      if (!data.url && isMounted) {
        return setDataFromURL([
          {
            id: 0,
            labelIdn: "Gagal mengambil data",
            labelEng: "Failed to retrieve data",
          },
        ]);
      }
      if (isListAccount) {
        await axios
          .post(`${data.url}`, {
            noCif: authData?.noCif,
            bahasa: language === "Eng" ? "en" : "id",
          })
          .then((res) => {
            const data = res.data.data;
            let options = [];
            if (data?.listTypeProduk) {
              data.listTypeProduk.forEach((val) => {
                if (val.type === "Savings" || val.type === "Tabungan") {
                  options = [...options, ...data.portotabungan];
                }
                if (val.type === "Deposit" || val.type === "Deposito") {
                  options = [...options, ...data.portotabungan];
                }
                if (val.type === "Pinjaman" || val.type === "Loan") {
                  options = [...options, ...data.portotabungan];
                }
              });
            } else {
              options = data;
            }
            setDataFromURL(() => {
              return options.map((el) => {
                return {
                  ...el,
                  prodName: el?.prodName?.replaceAll("R/K ", ""),
                };
              });
            });
            setFetching(false);
          });
      } else {
        await axios
          .get(data.url)
          .then((res) => {
            let data = res.data.data;
            const filtered = data.filter((el) => el.id && el.id !== "");
            setDataFromURL(filtered);
            // setLocalState({ handle: dataFromURL[0][`label${data.bahasa}`] || [] });
            // setDataFromURL(filtered);
          })
          .catch((err) => {
            isMounted &&
              setDataFromURL([
                {
                  id: 0,
                  labelIdn: "Gagal mengambil data",
                  labelEng: "Failed to retrieve data",
                },
              ]);
          });
      }
    };
    if (isMounted) {
      getFromURL();
    }
    return () => {
      isMounted = false;
    };
  }, [data.url]);

  useEffect(() => {
    if (localState) {
      if (
        dataFromURL.find(
          (el) => (+el?.id || +el?.accountNo) === +localState.handle
        )
      ) {
        handleFormChange(
          {
            target: {
              value:
                dataFromURL.find(
                  (el) => (+el?.id || +el?.accountNo) === +localState.handle
                ) || null,
            },
          },
          data.label.labelIdnLowerCase || altLabel,
          index,
          data
        );
        typeof handleFormBlur === "function" &&
          handleFormBlur(
            {
              target: {
                value: dataFromURL.find(
                  (el) => (+el?.id || +el?.accountNo) === +localState.handle
                ),
              },
            },
            data.label.labelIdnLowerCase
              ? data.label.labelIdnLowerCase
              : altLabel
          );
      }
    }
  }, [localState]);

  return (
    <Form.Group as={Col} className="mb-3">
      <Form.Label
        style={{
          minHeight: "1.5rem",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: "5px" }}>
          {data?.label?.[`label${data.bahasa}`]
            ?.replace("(Wajib diisi)", "")
            ?.replace("(Mandatory)", "")}
        </div>
        <div style={{ fontSize: "12px", fontWeight: "" }}>
          {data.props && data.props.required
            ? data.bahasa === "Idn"
              ? " (Wajib diisi)"
              : " (Mandatory)"
            : null}
        </div>
      </Form.Label>
      <Form.Select
        type={data.type}
        // placeholder={data.placeholder[`placeholder${data.bahasa}`]}
        required={data.props && data.props.required}
        disabled={data.props && data.props.disabled}
        value={
          formState[data.label[`labelIdnLowerCase`]]?.id ||
          formState[data.label[`labelIdnLowerCase`]]?.accountNo ||
          ""
        }
        onBlur={(e) => {}}
        onChange={(event) => {
          setLocalState((prev) => {
            return {
              ...prev,
              [data.label[`label${data.bahasa}LowerCase`] || altLabel]:
                dataFromURL.find(
                  (el) => (el?.id || +el?.accountNo) === +event.target.value
                ),
              handle: event.target.value,
            };
          });
        }}
        style={{
          color:
            formState[data.label[`labelIdnLowerCase`]]?.id ||
            formState[data.label[`labelIdnLowerCase`]]?.accountNo
              ? "#212529"
              : "#afafaf",
        }}
      >
        <option disabled hidden style={{ color: "gray" }} value="">
          {data.placeholder[`placeholder${data.bahasa}`]}
        </option>
        {data.options &&
          data.options.length > 0 &&
          data.options.map((val, i) => {
            return (
              <option
                style={{ color: "#212529" }}
                disabled={!val.id}
                key={i}
                value={val.id || 0}
              >
                {val.label[`label${data.bahasa}`]}
              </option>
            );
          })}
        {dataFromURL &&
          dataFromURL.length > 0 &&
          dataFromURL.map((el, idx) => {
            return (
              <option
                key={idx}
                value={el?.id || el?.accountNo || 0}
                disabled={el?.prodCode === "T10"}
                style={{ color: "#212529" }}
              >
                {el[`label${data.bahasa}`]
                  ? el[`label${data.bahasa}`]
                  : el.prefix
                  ? `${el.prefix} ${el.name && "-"} ${el.name}`
                  : `${el.prodName} - ${el?.accountNo}`}
              </option>
            );
          })}
      </Form.Select>
      {errorState?.[index]?.[
        data?.label[`labelIdnLowerCase`] || formState?.[altLabel]
      ] && (
        <Form.Text className="text-danger d-block">
          {
            errorState?.[index]?.[
              data?.label[`labelIdnLowerCase`] || formState?.[altLabel]
            ]
          }
        </Form.Text>
      )}
    </Form.Group>
  );
};

export const DropdownSumberDana = ({
  data,
  altLabel,
  errorState,
  formState,
  handleFormChange,
  handleFormBlur,
  index,
  form,
  stateArray,
  style,
}) => {
  const [dataOptions, setDataOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [fetching, setFetching] = useState(false);
  const formRef = useRef(null);
  const { authData } = useContext(AuthData);
  const { language } = useContext(LanguageContext);

  const isCurrency =
    form &&
    (form
      .flatMap((el) => el.components)
      .find((comp) => comp.name === "Currency")
      ?.label?.labelIdn?.toLowerCase()
      ?.replace(/[\/. -]/g, "") ||
      null);

  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: value?.acctCur || "IDR",
  });

  const { isLoading, data: listAccountData } = useQuery({
    queryKey: ["list", data?.url],
    queryFn: async () => {
      const res = await axios.post(`${data.url}`, {
        noCif: authData?.noCif,
        bahasa: language === "Eng" ? "en" : "id",
      });
      return res?.data?.data;
    },
  });

  useEffect(() => {
    setFetching(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (listAccountData && !isLoading) {
      const data = listAccountData;
      let options = [];
      data?.listTypeProduk?.forEach((val) => {
        if (
          val.type === "Saving" ||
          val.type === "Tabungan" ||
          val.type === "Savings" ||
          val.type === "tabungan"
        ) {
          options = [...options, ...data.portotabungan];
        }
      });
      setDataOptions(() => {
        return options.map((el) => {
          return { ...el, prodName: el?.prodName?.replaceAll("R/K ", "") };
        });
      });
    }
  }, [listAccountData]);
  useEffect(() => {
    //  setValue({
    //   ...val,
    //   availBalance: val.availBalance / 100 - selectedRed?.balance,
    // })
    const currentValue = formState?.[data?.label?.labelIdnLowerCase];
    const originBalance =
      dataOptions &&
      dataOptions?.find((el) => el.accountNo === currentValue?.accountNo)
        ?.availBalance / 100;
    if (currentValue) {
      const selectedRek = currentValue?.accountNo;
      const selectedRed = reduction?.find((el) => el.accountNo === selectedRek);
      if (
        originBalance &&
        selectedRed &&
        originBalance - selectedRed?.balance !== currentValue?.availBalance
      ) {
        setValue((prev) => {
          return {
            ...currentValue,
            availBalance: originBalance - selectedRed?.balance,
          };
        });
      } else {
        setValue(currentValue);
      }
    } else {
      setValue(null);
    }
  }, [index, formState?.[data?.label?.labelIdnLowerCase]]);

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, [dataOptions, authData, language]);

  let reduction = [];
  if (isCurrency && dataOptions) {
    const listForm = stateArray.slice(0, index);
    for (const rek of dataOptions) {
      const sumNominal = listForm.reduce((acc, curr) => {
        if (rek.accountNo === curr?.[data.label.labelIdnLowerCase]?.accountNo) {
          return +acc + +(curr?.[isCurrency]?.replace(/[ /.-]/g, "") || 0);
        }
        return acc;
      }, 0);
      reduction.push({ accountNo: rek.accountNo, balance: sumNominal });
    }
  }

  useEffect(() => {
    if (value) {
      handleFormChange(
        { target: { value: value } },
        data.label.labelIdnLowerCase || altLabel,
        index,
        data
      );
      typeof handleFormBlur === "function" &&
        handleFormBlur(
          { target: { value: value } },
          data.label.labelIdnLowerCase || altLabel
        );
    }
  }, [value]);
  function getDisplayedValue(data) {
    const dataFromOption = dataOptions.find(
      (el) => el?.accountNo === data?.accountNo
    );
    if (+dataFromOption?.availBalance === +data?.availBalance) {
      return +data?.availBalance / 100;
    } else {
      return +data?.availBalance;
    }
  }
  return (
    <Form.Group
      key={index}
      as={Col}
      className="mb-3 d-flex justify-content-around"
      style={{ ...style, flexDirection: "column" }}
      ref={formRef}
    >
      <Form.Label
        style={{
          minHeight: "1.5rem",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: "5px" }}>
          {data?.label?.[`label${data.bahasa}`]
            ?.replace("(Wajib diisi)", "")
            ?.replace("(Mandatory)", "")}
        </div>
        <div style={{ fontSize: "12px", fontWeight: "" }}>
          {data.props && data.props.required
            ? data.bahasa === "Idn"
              ? " (Wajib diisi)"
              : " (Mandatory)"
            : null}
        </div>
      </Form.Label>
      <div
        style={{
          position: "relative",
          display: "block",
          width: "100%",
          padding: "0.375rem 0.75rem",
          fontSize: "1rem",
          fontWeight: "400",
          lineHeight: "1.5",
          backgroundColor: data?.props?.disabled ? "#e9ecef" : "white",
          backgroundClip: "paddng-box",
          border: "1px solid #ced4da",
          appearance: "none",
          borderRadius: "0.25rem",
          transition:
            "border-color .15s ease-in-out, box-shadow .15s ease-in-out",
        }}
        onClick={() => !data?.props?.disabled && setIsOpen(!isOpen)}
      >
        <div
          className="d-flex flex-column"
          style={{
            userSelect: "none",
            paddingRight: "10px",
            color: value ? "#212529" : "#afafaf",
          }}
        >
          {!value ? (
            data?.placeholder?.[`placeholder${language}`]
          ) : (
            <div className="d-flex flex-column" style={{ color: "#212529" }}>
              <div
                className="overflow-hidden text-nowrap"
                style={{ fontWeight: "600", textOverflow: "ellipsis" }}
              >
                {value.prodName}
              </div>
              <div
                className="overflow-hidden text-nowrap"
                style={{ textOverflow: "ellipsis" }}
              >
                {`${value.accountNo} - ${value.accountName}`}
              </div>
              <div
                className="overflow-hidden text-nowrap"
                style={{ textOverflow: "ellipsis" }}
              >
                {formatter.format(getDisplayedValue(value))}
              </div>
            </div>
          )}
        </div>
        <ArrowDown
          style={{
            position: "absolute",
            right: "0.5rem",
            top: "50%",
            transform: "translate(0, -50%)",
            width: "0.75rem",
            height: "0.75rem",
          }}
          className={`w-3 h-3 absolute right-2 top-1/2 -translate-y-1/2 ${
            data?.props?.disabled && "hidden"
          }`}
        />
        {fetching && (
          <div
            className="animate-spinning"
            style={{
              position: "absolute",
              right: "1.5rem",
              top: "30%",
              transform: "translate(0, -50%)",
              width: "1rem",
              height: "1rem",
              border: "3px solid red",
              borderRadius: "50%",
              borderTop: "3px solid transparent",
            }}
          />
        )}
        {isOpen && (
          <div
            style={{
              overflowY: "auto",
              position: "absolute",
              left: "0",
              top: "101%",
              width: "100%",
              borderWidth: "1px",
              background: "#f9f9f9",
              maxHeight: "270px",
              zIndex: "9999",
              border: "1px solid lightgrey",
            }}
          >
            {
              // .filter((el) => el.prodCode !== "T21")
              dataOptions?.length > 0 ? (
                dataOptions
                  // .filter((el) => el.prodCode !== "T21")
                  .map((val, index) => {
                    return (
                      <div
                        className="dropdownSumberDana"
                        style={{
                          display: "flex",
                          paddingTop: "0.5rem",
                          paddingBottom: "0.5rem",
                          paddingLeft: "1rem",
                          paddingRight: "1rem",
                          flexDirection: "column",
                        }}
                        key={index}
                        onClick={() => {
                          const selectedRek = val?.accountNo;
                          const selectedRed = reduction?.find(
                            (el) => el.accountNo === selectedRek
                          );
                          if (selectedRed) {
                            setValue({
                              ...val,
                              availBalance:
                                val.availBalance / 100 - selectedRed?.balance,
                            });
                          } else {
                            setValue({
                              ...val,
                              availBalance: val.availBalance / 100,
                            });
                          }
                        }}
                      >
                        <div style={{ fontWeight: "600" }}>{val.prodName}</div>
                        <div>{`${val.accountNo} - ${val.accountName}`}</div>
                        <div>{formatter.format(getDisplayedValue(val))}</div>
                      </div>
                    );
                  })
              ) : (
                <option disabled>Data tidak ditemukan</option>
              )
            }
          </div>
        )}
      </div>
      {errorState?.[index]?.[
        data?.label[`labelIdnLowerCase`] || formState?.[altLabel]
      ] && (
        <Form.Text className="text-danger d-block">
          {
            errorState?.[index]?.[
              data?.label[`labelIdnLowerCase`] || formState?.[altLabel]
            ]
          }
        </Form.Text>
      )}
    </Form.Group>
  );
};

export const Datalist = ({
  data,
  altLabel,
  errorState,
  formState,
  handleFormChange,
  handleFormBlur,
  index,
}) => {
  const { language } = useContext(LanguageContext);
  const { authData } = useContext(AuthData);

  const isListAccount = data?.url.includes("list-account/list");

  const fetchData = async ({ url, authData, language, isListAccount }) => {
    if (!url) {
      return [
        {
          value: "",
          labelIdn: "Gagal mengambil data",
          labelEng: "Failed to retrieve data",
        },
      ];
    }

    if (isListAccount) {
      const response = await axios.post(url, {
        noCif: authData?.noCif,
        bahasa: language === "Eng" ? "en" : "id",
      });
      const data = response.data.data;
      let options = [];

      data.listTypeProduk.forEach((val) => {
        if (val.type === "Saving" || val.type === "Tabungan") {
          options = [...options, ...data.portotabungan];
        }
        // Add more conditions as needed
      });

      return options.map((el) => ({
        ...el,
        prodName: el?.prodName?.replaceAll("R/K ", ""),
      }));
    } else {
      try {
        const response = await axios.get(url);
        const data = response.data.data;
        const filtered = data.filter((el) => el.id && el.id !== "");

        return filtered;
      } catch (err) {
        return [
          {
            id: 0,
            labelIdn: "Gagal mengambil data",
            labelEng: "Failed to retrieve data",
          },
        ];
      }
    }
  };

  let {
    data: dataFromURL,
    isLoading,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: isListAccount ? ["listDatalist"] : [data?.url],
    queryFn: async () =>
      await fetchData({ url: data.url, authData, language, isListAccount }),
    initialData: null,
  });

  if (isSuccess) {
  }

  const stateVal =
    formState?.[data?.label?.[`labelIdnLowerCase`]] || formState?.[altLabel];
  const isValObj = typeof stateVal === "object";
  let val;
  if (!isValObj && dataFromURL) {
    for (const el of dataFromURL) {
      if (el?.id === stateVal) {
        console.log("masuk id")
        val = {
          ...el,
          label:
            el[`label${language}`] ||
            el?.label?.[`label${language}`] ||
            `${el?.accountNo} - ${el?.accountName}`,
          value: el.id,
        };
        break; // Exit the loop when a match is found
      } else if (el?.accountNo && el?.accountNo === stateVal) {
        console.log("masuk account no")
        val = {
          ...el,
          label: `${el?.accountNo} - ${el?.accountName}`,
          value: el?.accountNo,
        };
        break; // Exit the loop when a match is found
      }
    }
  } else if (dataFromURL && isValObj && stateVal) {
    console.log("masuk else if")
    val = {
      ...stateVal,
      label:
        stateVal[`label${language}`] ||
        stateVal?.label?.[`label${language}`] ||
        `${stateVal?.accountNo} - ${stateVal?.accountName}`,
      value: stateVal?.id || stateVal?.accountNo,
    };
  }

  console.log(val, "DATALIST");
  console.log(stateVal);
  console.log(dataFromURL)

  return (
    <Form.Group
      as={Col}
      className="mb-3 d-flex justify-content-start"
      style={{ flexDirection: "column" }}
    >
      <Form.Label
        style={{
          minHeight: "1.5rem",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: "5px" }}>
          {data?.label?.[`label${data.bahasa}`]
            ?.replace("(Wajib diisi)", "")
            ?.replace("(Mandatory)", "")}
        </div>
        <div style={{ fontSize: "12px", fontWeight: "" }}>
          {data.props && data.props.required
            ? data.bahasa === "Idn"
              ? " (Wajib diisi)"
              : " (Mandatory)"
            : null}
        </div>
      </Form.Label>
      <Select
        value={val || ""}
        noOptionsMessage={() =>
          language === "Idn"
            ? "Gagal mengambil data"
            : "Failed to retrieve data"
        }
        options={dataFromURL?.map((value) => {
          return {
            label:
              value?.[`label${language}`] ||
              `${value?.accountNo} - ${value?.accountName}`,
            value: value?.id || value?.accountNo,
          };
        })}
        styles={{
          indicatorSeparator: () => null,
          dropdownIndicator: (base) => ({
            ...base,
            color: "rgb(84, 87, 92)",
            paddingRight: "4px",
            "& svg": { strokeWidth: "0.75", width: "17px", height: "17px" },
          }),
          control: (baseStyle, state) => ({
            ...baseStyle,
            borderColor: state?.isFocused ? "#86b7fe" : "#E5E7EB",
            boxShadow: state?.isFocused && "0 0 0 0.25rem rgba(13,110,253,.25)",
          }),
          placeholder: (styles) => ({
            ...styles,
            color: "#afafaf", // Change the color to your desired color
          }),
        }}
        placeholder={data.placeholder[`placeholder${data.bahasa}`]}
        onChange={(e) => {
          handleFormChange(
            {
              target: {
                value: isListAccount
                  ? e.value
                  : { ...dataFromURL.find((el) => el?.id === e.value), ...e },
              },
            },
            data?.label?.labelIdnLowerCase || altLabel,
            index,
            data
          );
        }}
      />
      {errorState?.[index]?.[
        data?.label[`labelIdnLowerCase`] || formState?.[altLabel]
      ] && (
        <Form.Text className="text-danger d-block">
          {
            errorState?.[index]?.[
              data?.label[`labelIdnLowerCase`] || formState?.[altLabel]
            ]
          }
        </Form.Text>
      )}
    </Form.Group>
  );
};

export const TextArea = ({
  data,
  altLabel,
  errorState,
  formState,
  handleFormChange,
  handleFormBlur,
  index,
}) => {
  return (
    <Form.Group as={Col} className="mb-3">
      <Form.Label
        style={{
          minHeight: "1.5rem",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: "5px" }}>
          {data?.label?.[`label${data.bahasa}`]
            ?.replace("(Wajib diisi)", "")
            ?.replace("(Mandatory)", "")}
        </div>
        <div style={{ fontSize: "12px", fontWeight: "" }}>
          {data.props && data.props.required
            ? data.bahasa === "Idn"
              ? " (Wajib diisi)"
              : " (Mandatory)"
            : null}
        </div>
      </Form.Label>
      <Form.Control
        style={{ resize: "none" }}
        rows={3}
        className="formBuilder2"
        as="textarea"
        type={data.type}
        placeholder={
          typeof data.placeholder[`placeholder${data.bahasa}`] === "object"
            ? data.placeholder[`placeholder${data.bahasa}`].target.value
            : data.placeholder[`placeholder${data.bahasa}`]
        }
        // placeholder={data.placeholder[`placeholder${data.bahasa}`]}
        name={data.label[`label${data.bahasa}LowerCase`] || altLabel}
        required={data.props && data.props.required}
        disabled={data.props && data.props.disabled}
        value={
          formState[data.label[`labelIdnLowerCase`] || formState[altLabel]] ||
          ""
        }
        onKeyDown={(e) => {
          if (e.keyCode === 13) e.preventDefault();
        }}
        onBlur={(e) => {
          typeof handleFormBlur === "function" &&
            handleFormBlur(
              e,
              data.label.labelIdnLowerCase
                ? data.label.labelIdnLowerCase
                : altLabel
            );
        }}
        onChange={(e) => {
          handleFormChange(
            e,
            data.label.labelIdnLowerCase
              ? data.label.labelIdnLowerCase
              : altLabel,
            index,
            data
          );
        }}
      ></Form.Control>
    </Form.Group>
  );
};

export const TextInfo = ({
  data,
  altLabel,
  errorState,
  formState,
  handleFormChange,
  handleFormBlur,
  index,
}) => {
  return (
    <Form.Group as={Col} className="mb-3">
      <Form.Label
        style={{
          minHeight: "1.5rem",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: "5px" }}>
          {data?.label?.[`label${data.bahasa}`]
            ?.replace("(Wajib diisi)", "")
            ?.replace("(Mandatory)", "")}
        </div>
        <div style={{ fontSize: "12px", fontWeight: "" }}>
          {data?.props?.required && data.label[`label${data.bahasa}`]
            ? data.label[`label${data.bahasa}`].includes("(Wajib diisi)") ||
              data.label[`label${data.bahasa}`].includes("(Mandatory)")
              ? " (Wajib diisi)"
              : " (Mandatory)"
            : null}
        </div>
      </Form.Label>
      <div
        className="d-flex align-items-center"
        style={{
          minHeight: "1.5rem",
          padding: "0.375rem 0.75rem",
          paddingLeft: "0",
        }}
      >
        {formState[data.label[`labelIdnLowerCase`] || formState[altLabel]] ||
          "" ||
          data?.placeholder?.placeholderIdn}
      </div>
    </Form.Group>
  );
};

export const RadioButton = ({
  data,
  altLabel,
  errorState,
  formState,
  handleFormChange,
  handleFormBlur,
  index,
}) => {
  return (
    <Form.Group as={Col} className="mb-3">
      <Form.Label
        style={{
          minHeight: "1.5rem",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: "5px" }}>
          {data?.label?.[`label${data.bahasa}`]
            ?.replace("(Wajib diisi)", "")
            ?.replace("(Mandatory)", "")}
        </div>
        <div style={{ fontSize: "12px", fontWeight: "" }}>
          {data?.props?.required && data.label[`label${data.bahasa}`]
            ? data.label[`label${data.bahasa}`].includes("(Wajib diisi)") ||
              data.label[`label${data.bahasa}`].includes("(Mandatory)")
              ? " (Wajib diisi)"
              : " (Mandatory)"
            : null}
        </div>
      </Form.Label>
      <Form.Check
        type={data.type}
        label={data.placeholder[`placeholder${data.bahasa}`]}
        name={data.label[`label${data.bahasa}LowerCase`] || altLabel}
        required={data.props && data.props.required}
        disabled={data.props && data.props.disabled}
        checked={
          formState[data?.label?.[`labelIdnLowerCase`] || altLabel] ===
          data.placeholder[`placeholder${data.bahasa}`]
        }
        onBlur={(e) => {}}
        onChange={(e) => {
          handleFormChange(
            {
              target: { value: data.placeholder[`placeholder${data.bahasa}`] },
            },
            data.label.labelIdnLowerCase
              ? data.label.labelIdnLowerCase
              : altLabel,
            index,
            data
          );
          typeof handleFormBlur === "function" &&
            handleFormBlur(
              {
                target: {
                  value: data.placeholder[`placeholder${data.bahasa}`],
                },
              },
              data.label.labelIdnLowerCase
                ? data.label.labelIdnLowerCase
                : altLabel
            );
        }}
      ></Form.Check>
    </Form.Group>
  );
};

export const Checkbox = ({
  data,
  altLabel,
  errorState,
  formState,
  handleFormChange,
  handleFormBlur,
  index,
}) => {
  return (
    <Form.Group
      as={Col}
      className="mb-3 d-flex justify-content-around"
      style={{ flexDirection: "column" }}
    >
      <Form.Check
        type={data.type}
        name={data.label[`label${data.bahasa}LowerCase`] || altLabel}
        required={data.props && data.props.required}
        disabled={data.props && data.props.disabled}
        id={data.placeholder[`placeholder${data.bahasa}`]}
      >
        <Form.Check.Input
          value={formState[data.label[`labelIdnLowerCase`] || altLabel] || ""}
          style={{
            marginRight: "revert",
            alignSelf: "center",
            cursor: "pointer"
          }}
          required={data.props.required}
          type={data.type}
          checked={
            data?.label?.labelIdn === "tnc" ?
            formState[data.label[`labelIdnLowerCase`] || altLabel] :
            formState[data.label[`labelIdnLowerCase`] || altLabel] === data.placeholder.placeholderIdn
          }
          onBlur={(e) => {}}
          onChange={(e) => {
            const value = e.target.checked ? data.placeholder[`placeholder${data.bahasa}`] : null;
            e.target.checked &&
            data.placeholder.placeholderIdn ===
              "Apakah Dana Tunai Akan Diambil Oleh Pihak Lain"
              ? Inputgambar(
                  data,
                  altLabel,
                  formState,
                  handleFormChange,
                  handleFormBlur,
                  index
                )
              : handleFormChange(
                  {
                    target: {
                      value: data?.label?.labelIdn === "tnc" ? e.target.checked : value
                    },
                  },
                  data.label.labelIdnLowerCase
                    ? data.label.labelIdnLowerCase
                    : altLabel,
                  index,
                  data
                );
            // typeof handleFormBlur === "function" &&
            //   handleFormBlur(
            //     {
            //       target: {
            //         value: data.placeholder[`placeholder${data.bahasa}`],
            //       },
            //     },
            //     data.label.labelIdnLowerCase
            //       ? data.label.labelIdnLowerCase
            //       : altLabel
            //   );
          }}
        />
        <Form.Check.Label className="align-items-center">
          {data.placeholder[`placeholder${data.bahasa}`]}
        </Form.Check.Label>
      </Form.Check>
    </Form.Group>
  );
};

export const Inputgambar = ({
  data,
  altLabel,
  errorState,
  formState,
  handleFormChange,
  handleFormBlur,
  index,
}) => {
  return (
    <>
      {/* <InputGroup> */}
      <Card>
        <Card.Body
          style={{
            width: "100%",
            height: "100%",
            padding: "4rem 0", //idform 11 = unggahktpawal page
            textAlign: "center",
          }}
        >
          <input
            hidden
            type="file"
            id={"image"}
            // onChange={(event) => {
            // }}
          />
          <label for={"image"}>
            <Card.Img
              for={"image"}
              variant="top"
              src={require("../DipsGraphicMobileUI/addicon.png")}
              style={{ width: "32px", height: "32px", cursor: "pointer" }}
            />
          </label>
        </Card.Body>
        <Card.Body
          style={{
            width: "100%",
            height: "100%",
            padding: "1rem 0", //idform 11 = unggahktpawal page
            textAlign: "center",
          }}
        >
          <Card.Img
            hidden={false}
            // name={props.label[`label${'Idn'}`] + "selectedimage"}
            // id={props.label[`label${'Idn'}`] + "selectedimage"}
            variant="top"
            // src={typeof (state[`${komprespropslabel(props.label[`label${'Idn'}`])}`]) === 'object' ? URL.createObjectURL(state[`${komprespropslabel(props.label[`label${'Idn'}`])}`]) : state[`${komprespropslabel(props.label[`label${'Idn'}`])}`]}
            style={{
              maxWidth: "100%",
              maxHeight: "8rem",
              objectFit: "scale-down",
            }}
          />
          <Card.Img
            hidden={false}
            // name={props.urutan + "selectedimagedelete"}
            // id={props.urutan + "selectedimagedelete"}
            variant="top"
            src={require("../DipsGraphicMobileUI/tongsampah.png")}
            style={{
              width: "auto",
              maxHeight: "1rem",
              objectFit: "scale-down",
              position: "absolute",
              bottom: "1rem",
              right: "1rem",
            }}
            // onClick={() => runAfterImageDelete("", handleChange)}
          />
        </Card.Body>
      </Card>
      {/* </InputGroup> */}
    </>
  );
};

export const Datepicker = ({
  data,
  altLabel,
  errorState,
  formState,
  handleFormChange,
  handleFormBlur,
  index,
}) => {
  // const valuetanggal = moment().format("YYYY-MM-DD")
  // useEffect(() => {
  //   handleFormChange(
  //     { target: { value: valuetanggal } },
  //     data.label.labelIdnLowerCase ? data.label.labelIdnLowerCase : altLabel,
  //     index,
  //     data
  //   );
  //   handleFormBlur(
  //     { target: { value: valuetanggal } },
  //     data.label.labelIdnLowerCase ? data.label.labelIdnLowerCase : altLabel,
  //     index,
  //     data
  //   );
  // }, []);
  return (
    <Form.Group
      as={Col}
      className="mb-3 d-flex justify-content-start"
      style={{ flexDirection: "column" }}
    >
      <Form.Label
        style={{
          minHeight: "1.5rem",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: "5px" }}>
          {data?.label?.[`label${data.bahasa}`]
            ?.replace("(Wajib diisi)", "")
            ?.replace("(Mandatory)", "")}
        </div>
        <div style={{ fontSize: "12px", fontWeight: "" }}>
          {data.props && data.props.required
            ? data.bahasa === "Idn"
              ? " (Wajib diisi)"
              : " (Mandatory)"
            : null}
        </div>
      </Form.Label>
      <input
        style={{
          display: "block",
          width: "100%",
          padding: "0.375rem 0.75rem",
          fontSize: "1rem",
          fontWeight: "400",
          lineHeight: "1.5",
          color:
            formState[data.label[`labelIdnLowerCase`] || formState[altLabel]] &&
            !data.props?.disabled
              ? "#212529"
              : "#afafaf",
          backgroundColor: "white",
          backgroundClip: "paddng-box",
          border: "1px solid #ced4da",
          appearance: "none",
          borderRadius: "0.25rem",
          transition:
            "border-color .15s ease-in-out, box-shadow .15s ease-in-out",
        }}
        type={data.type}
        required={data.props && data.props.required}
        disabled={data.props && data.props.disabled}
        data-date={
          moment(
            formState[data.label[`labelIdnLowerCase`] || formState[altLabel]],
            "DD-MM-YYYY"
          ).format("DD-MM-YYYY") || ""
        }
        // defaultValue={valuetanggal}
        value={formState[data.label[`labelIdnLowerCase`] || formState[altLabel]] || ""}
        onChange={(e) => {
          handleFormChange(
            e,
            data.label.labelIdnLowerCase
              ? data.label.labelIdnLowerCase
              : altLabel,
            index,
            data
          );
          // setValuetanggal(e.target.value);
        }}
      />
    </Form.Group>
  );
};

export const InputFile = ({
  data,
  altLabel,
  errorState,
  formState,
  handleFormChange,
  handleFormBlur,
  index,
}) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState(t("Tidak ada file yang dipilih"))
  console.log(data)
  return (
    <Form.Group as={Col} className="mb-3 col-lg-6">
      <Form.Label
        style={{
          minHeight: "1.5rem",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: "5px" }}>
          {data?.label?.[`label${data.bahasa}`]
            ?.replace("(Wajib diisi)", "")
            ?.replace("(Mandatory)", "")}
        </div>
        <div style={{ fontSize: "12px", fontWeight: "" }}>
          {data.props && data.props.required
            ? data.bahasa === "Idn"
              ? " (Wajib diisi)"
              : " (Mandatory)"
            : null}
        </div>
      </Form.Label>
      <div
        className="box-input-receipt d-flex justify-content-center flex-column px-3"
        style={{
          border: "2px dashed #C8CDD1",
          borderRadius: "7px",
          height: "3rem",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          cursor: "pointer",
          backgroundColor: "#F0F3F8",
        }}
        onClick={() => document.querySelector("#input-file").click()}
      >
        <input
          className="form-control"
          type="file"
          id={"input-file"}
          accept="application/*"
          hidden
          onChange={(e) => {
            handleFormChange(
              {
                target: {
                  value: e.target.files[0],
                },
              },
              data.label.labelIdnLowerCase
                ? data.label.labelIdnLowerCase
                : altLabel,
              index,
              data
            );
            const file = e.target.files[0];
            setFileName(file.name);
          }}
          // onBlur={onBlur}
          // onChange={handleImageChange}
          // onChange={(e) => {
          //     e.target.files[0] && setFileName(e.target.files[0].name)
          //     setPreview(URL.createObjectURL(e.target.files[0]))
          //     setImage({ file: e.target.files[0] })
          //     setFormState({...formState, image: e.target.files[0] })
          // }}
        />

          <div>
              {fileName && (
                  <div className='d-flex justify-content-between align-items-center'>
                      <p style={{color: formState?.suratkehilangan === null || formState?.suratkehilangan === undefined ? '#B5B5B5' : null}}>{fileName}</p>
                      <MdDelete 
                          className='text-danger' 
                          style={{
                              fontSize: "1.5rem",
                              cursor: "pointer"
                          }}
                          onClick={(e) => {
                              e.stopPropagation()
                              setFileName(t("Tidak ada file yang dipilih"))
                              document.querySelector('#input-file').value = null
                              handleFormChange(
                                {target: {
                                  value: null
                                }},
                                data.label.labelIdnLowerCase
                                    ? data.label.labelIdnLowerCase
                                    : altLabel,
                                index,
                                data
                              );
                          }}
                      />
                  </div>
              )}
          </div>
      </div>
    </Form.Group>
  );
};
